import React, { useContext } from "react"
import styled from "styled-components"

import BranchContext from "../../context/BranchContext"

import SvgIcon from "../../zzz/atoms/icons/svg-icon"

import MapMarkerIcon from "../../zzz/atoms/icons/files/map-marker.jsx"

export const FloatingBranchSelectButton = ({ className = "" }) => {
  const {
    selectedBranch,
    branchSelectPopupOpen,
    setBranchSelectPopupOpen,
  } = useContext(BranchContext)
  return (
    <StyledButton
      className={className}
      onClick={() => {
        setBranchSelectPopupOpen(true)
      }}
      branchSelectPopupOpen={branchSelectPopupOpen}
    >
      <SvgIcon SvgComponent={MapMarkerIcon} hue="ruby" shade="080" size="md" />
      <ButtonTextStyling>
        {!selectedBranch ? "Select Store" : `Change store`}
      </ButtonTextStyling>
    </StyledButton>
  )
}

export default FloatingBranchSelectButton

const buttonTextWidth = "120px"

const StyledButton = styled.button`
  display: ${({ branchSelectPopupOpen }) =>
    branchSelectPopupOpen ? "none" : "flex"};
  flex-direction: row;
  outline: none;
  position: fixed;
  overflow: hidden;
  top: 300px;
  right: -${buttonTextWidth};
  padding: 0;
  z-index: 1000000;
  border-width: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: ${({ theme }) => theme.colors.midnight["100"]};
  box-shadow: 0 0 10px 5px rgba(97, 97, 97, 0.25);
  transition: all 0.3s ease-in-out;
  > .icon {
    padding-top: 2px;
    background: white;
  }
  &:hover {
    transition: all 0.3s ease-in-out;
    right: 0;
  }
`
const ButtonTextStyling = styled.div`
  color: white;
  text-align: center;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  width: ${buttonTextWidth};
  line-height: 1;
`
