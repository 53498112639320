import React, { useState, useEffect, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

import BranchContext from "../../context/BranchContext"
import ProductContext from "../../context/ProductContext"

import BranchLocation from "./BranchLocation"
import LocationSearch from "./LocationSearch"

import { calcCoordDistanceApprox, determineShippingClass } from "../../lib/util"

const BranchSelector = () => {
  const [userCoordinates, setUserCoordinates] = useState(null)
  const [selectedBranchData, setSelectedBranchData] = useState(null)
  const [branchesSorted, setBranchesSorted] = useState(null)
  const { branchList, selectedBranch } = useContext(BranchContext)
  const { currentProduct } = useContext(ProductContext)

  const queryResponse = useStaticQuery(QUERY)
  const deliveryTsCs = getTsCs(queryResponse)

  useEffect(() => {
    const branchesSorted_temp = []
    for (let i = 0; i < branchList.length; ++i) {
      const branch = branchList[i]?.branch_details
      if (branch?.branchMoneyworksId) {
        const distance =
          userCoordinates && calcCoordDistanceApprox(branch, userCoordinates)
        const stock_by_location = currentProduct?.stockUpdateData?.map?.(
          (item) => item?.stock_by_location
        )
        const branchShippingClass = !stock_by_location
          ? null
          : determineShippingClass(
              "",
              stock_by_location,
              branch?.branchMoneyworksId
            )
        const branchParsed = {
          ...branch,
          distance,
          branchShippingClass,
        }
        if (selectedBranch && selectedBranch == branch?.branchMoneyworksId) {
          setSelectedBranchData(branchParsed)
        } else {
          branchesSorted_temp.push(branchParsed)
        }
      }
    }
    if (userCoordinates) {
      branchesSorted_temp.sort((a, b) => a.distance - b.distance)
    } else {
      branchesSorted_temp.sort((a, b) => {
        if (a.branchName < b.branchName) {
          return -1
        }
        if (a.branchName > b.branchName) {
          return 1
        }
        return 0
      })
    }
    setBranchesSorted(branchesSorted_temp)
  }, [userCoordinates, selectedBranch, currentProduct])
  return (
    <>
      <div>
        <LocationSearch setUserCoordinates={setUserCoordinates} />
      </div>
      {!selectedBranchData ? null : (
        <BranchLocation
          key="selected"
          className="selected-branch"
          selected
          TsCs={deliveryTsCs}
          productSelected={currentProduct == null ? false : true}
          {...selectedBranchData}
        />
      )}
      {branchesSorted?.map?.((branch, branch_i) => (
        <BranchLocation
          key={branch_i}
          TsCs={deliveryTsCs}
          productSelected={currentProduct == null ? false : true}
          {...branch}
        />
      )) ?? null}
    </>
  )
}

export default BranchSelector

// ======================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ======================

const QUERY = graphql`
  {
    localWpGraphQlTmwGeneralSettings {
      tmw_settings {
        deliveryTsCs
      }
    }
  }
`

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================

const getTsCs = ({
  localWpGraphQlTmwGeneralSettings: {
    tmw_settings: { deliveryTsCs },
  },
}) => deliveryTsCs
