import React from "react"

const LogoVisa = ({ fill }) => (
  <svg width="16" height="13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.85 5.44c.1.42.17.83.25 1.23h-.9l.43-1.17.06-.14.08-.27.08.35zm2.9-4.13v9.63c0 .36-.13.67-.38.93-.26.25-.57.38-.93.38H1.3c-.36 0-.67-.13-.93-.38a1.27 1.27 0 01-.38-.93V1.3C0 .95.13.64.38.38.64.13.95 0 1.31 0h13.13c.36 0 .67.13.93.38.25.26.38.57.38.93zM4.18 8.18l1.73-4.24H4.73l-1.07 2.9-.49-2.55c-.05-.22-.23-.33-.52-.35H.9l-.03.08c.42.1.8.26 1.15.46l.99 3.7h1.17zm2.57 0l.69-4.24h-1.1l-.68 4.24h1.1zm3.83-1.37c0-.48-.3-.87-.93-1.18-.22-.1-.38-.2-.48-.27-.1-.07-.14-.16-.13-.25 0-.09.06-.17.16-.24.1-.08.25-.11.45-.11.3-.02.57.03.82.16l.11.03.17-.9c-.31-.13-.64-.2-.99-.2-.55 0-1 .14-1.34.4-.34.27-.52.61-.53 1.04-.01.43.31.8.97 1.14.2.09.35.17.44.26.09.08.13.16.13.26 0 .12-.06.22-.2.3-.14.07-.3.1-.5.1-.19 0-.35 0-.5-.04a1.91 1.91 0 01-.5-.17l-.13-.09-.16.96c.3.15.72.23 1.23.25.56 0 1.02-.13 1.38-.4.35-.26.53-.61.53-1.05zm3.86 1.37l-.88-4.24h-.85c-.29 0-.48.12-.57.35L10.5 8.18h1.15l.22-.63h1.42l.14.63h1z"
      fill={fill}
    />
  </svg>
)

export default LogoVisa
