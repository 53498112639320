import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import ArrowRight from "../../../atoms/icons/files/arrow-right"
import { formatPhoneToLink, path } from "../../../../lib/util"
import { LinkWrapper as Link } from "../../../../utils/linkWrapper"
import SvgIcon from "../../../atoms/icons/svg-icon"
import Phone from "../../../atoms/icons/files/phone.jsx"
import Mobile from "../../../atoms/icons/files/mobile.jsx"
import LocationPin from "../../../atoms/icons/files/location-pin.jsx"
import Calendar from "../../../atoms/icons/files/calendar.jsx"

const FeaturedCard = ({
  title = "Title",
  slug,
  raised,
  branch,
  phone_1,
  phone_2,
  hours,
  address,
  imgBorder,
}) => {
  const data = useStaticQuery(PLACEHOLDER)

  const branchName = path(["branch_details", "branchName"], branch)

  const fluid = path(
    [
      "branch_details",
      "image",
      "imageFile",
      "localFile",
      "childImageSharp",
      "fluid",
    ],
    branch
  )

  return (
    <StyledLink raised={raised} to={`/contact-us/${slug}`}>
      <StyledBackground
        fluid={fluid || data.file.childImageSharp.fluid}
        imgBorder={imgBorder}
      >
        <div className="overlay">
          <div className="title">
            <h3>
              {branchName || title} <ArrowRight fill="red" size="md" />
            </h3>
          </div>
        </div>
      </StyledBackground>
      {phone_1 && (
        <div className="horizontal">
          <IconText>
            <SvgIcon SvgComponent={Phone} hue="tusk" shade="080" />
            <div
              className="value"
              dangerouslySetInnerHTML={{ __html: formatPhoneToLink(phone_1) }}
            />
          </IconText>
        </div>
      )}
      {phone_2 && (
        <div className="horizontal">
          <IconText>
            <SvgIcon SvgComponent={Mobile} hue="tusk" shade="080" />
            <div
              className="value"
              dangerouslySetInnerHTML={{ __html: formatPhoneToLink(phone_2) }}
            />
          </IconText>
        </div>
      )}
      {address && (
        <div className="address">
          <IconText>
            <SvgIcon SvgComponent={LocationPin} hue="tusk" shade="080" />
            <div className="value">{address}</div>
          </IconText>
        </div>
      )}
      {hours && (
        <div className="times">
          <IconText>
            <SvgIcon SvgComponent={Calendar} hue="tusk" shade="080" />
            <div className="value">{hours}</div>
          </IconText>
        </div>
      )}
    </StyledLink>
  )
}

export default FeaturedCard

const IconText = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 10px;
  align-items: flex-start;
  justify-items: center;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.midnight["040"]};
  .value {
    justify-self: flex-start;
  }
  a {
    color: ${({ theme }) => theme.colors.midnight["040"]} !important;
  }
  span.subtle {
    color: ${({ theme }) => theme.colors.tusk["080"]};
  }
`

const StyledBackground = styled(BackgroundImage)`
  ${({ imgBorder }) => imgBorder && "border: 10px solid white;"}
  .overlay {
    min-height: 200px;
    display: flex;
    align-items: flex-end;
  }
  .title {
    padding: 10px;
    background: #071322d1;
    width: 100%;
    h3 {
      color: white;
      text-align: left;
    }
  }
`
const StyledLink = styled(Link)`
  background: white;
  border-radius: 4px;
  display: block;
  overflow: hidden;
  transition: all 200ms ease-in-out;
  ${({ raised }) => raised && "box-shadow: 0 13px 27px 0 rgba(97,97,97,0.25);"}
  &:hover {
    ${({ raised }) =>
      raised && "box-shadow: 0 35px 27px -13px rgba(97,97,97,0.25);"}
    transform:translateY(-4px);
  }
  img {
    min-height: 300px;
    width: 100%;
  }
  .address {
    padding: 10px;
    background: #f6f6f6;
  }
  .times {
    padding: 10px;
    > div {
      white-space: pre;
    }
  }
  .horizontal {
    padding: 10px;
  }
`

const PLACEHOLDER = graphql`
  {
    file(relativePath: { eq: "team-image-placeholder.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
