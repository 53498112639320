import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const SwiftComLogo = ({ text = "Powered by ", fluid }) => {
  return (
    <SwiftComLogoStyle href="https://www.swiftcom.co.za" target="_blank">
      <TextBox>{text}</TextBox>
      <Img
        style={{
          height: "20px",
          width: "76px",
        }}
        imgStyle={{
          maxHeight: "100%",
          maxWidth: "100%",
          backgroundColor: "white",
          borderRadius: "6px",
          padding: "5px",
        }}
        fluid={fluid}
      />
    </SwiftComLogoStyle>
  )
}

export default SwiftComLogo

const TextBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  text-transform: uppercase;
  color: white;
`
const SwiftComLogoStyle = styled.a`
  font-size: 10px;
  max-width: fit-content;
  padding: 6px 8px;
`
