import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"

import CartContext from "../../../context/CartContext"
import Logo from "../../atoms/logo"

import { getCompareList } from "../../../components/Products/CompareProducts"

import CartIcon from "../../../components/CartIcon/CartIcon"
import Grid from "../../layout/grid"
import SvgIcon from "../../atoms/icons/svg-icon"
import Home from "../../atoms/icons/files/home.jsx"
import LocationPin from "../../atoms/icons/files/location-pin.jsx"
import ListCheck from "../../atoms/icons/files/list-check.jsx"

import Searchbar from "../../../components/Search/Searchbar"

import LinkWrapper from "../../../utils/linkWrapper"

const headerMain = ({ location, isInStoreFrontendParent = false }) => {
  const { setCartDrawerOpen, setCompareDrawerOpen, compareDrawer } = useContext(
    CartContext
  )

  const pathname = typeof location != "undefined" ? location : ""

  const [compareCount, setCompareCount] = useState(0)

  const updateCompareCount = () => {
    const items = getCompareList()
    const length = items ? items.length : 0
    setCompareCount(length)
  }

  useEffect(updateCompareCount, [])
  useEffect(() => {
    if (compareDrawer) {
      updateCompareCount()
    }
  }, [compareDrawer])

  return (
    <HeaderMainWrapper>
      <Grid spacing={`1fr ${isInStoreFrontendParent ? "1fr " : ""}2fr 1fr`}>
        <StyledLogo to="/">
          <Logo />
        </StyledLogo>
        {isInStoreFrontendParent && (
          <StyledFlatlist to="/flatlist/">
            <div>FLATLIST</div>
          </StyledFlatlist>
        )}
        <Searchbar />
        <IconsNav>
          <IconsBox to="/contact-us/">
            <SvgIcon
              SvgComponent={LocationPin}
              hue="danger"
              shade="fontColor"
              size="lg"
            />
            Stores
          </IconsBox>
          {pathname.includes("compare-products") ? null : (
            <IconsBox as="div" onClick={() => setCompareDrawerOpen(true)}>
              {compareCount > 0 && (
                <span className="notify">{compareCount}</span>
              )}
              <SvgIcon
                SvgComponent={ListCheck}
                hue="danger"
                shade="fontColor"
                size="lg"
              />
              Compare
            </IconsBox>
          )}
          <IconsBox
            as="div"
            onClick={(e) => {
              setCartDrawerOpen(true)
              e.preventDefault() // Do not perform default "LinkWrapper" navigate
            }}
          >
            <CartIcon />
          </IconsBox>
        </IconsNav>
      </Grid>
    </HeaderMainWrapper>
  )
}

const HeaderMainWrapper = styled.div`
  background: ${(props) => props.theme.colors.midnight["080"]};
  color: ${({ theme }) => theme.colors.white["000"]};
  padding: 10px 0;
`
const StyledLogo = styled(LinkWrapper)`
  svg {
    height: 50px;
  }
`
const StyledFlatlist = styled(LinkWrapper)`
  width: 180px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.ruby["060"]};
  border-radius: 20px;
  div {
    color: white;
    font-size: 32px;
    font-weight: 600;
  }
`

const IconsNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`

const IconsBox = styled(LinkWrapper)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  position: relative;
  text-align: center;
  color: #fff;
  min-width: 35px;
  span.notify {
    color: #fff;
    text-align: center;
    position: absolute;
    top: 14px;
    right: 10px;
    background: ${({ theme }) => theme.colors.ruby["060"]};
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  padding: 10px;
  transition: all 200ms ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
`

export default headerMain
