import React, { memo } from "react"
import styled from "styled-components"
import Grid from "../../../layout/grid"
import SvgIcon from "../../../atoms/icons/svg-icon"
import Search from "../../../atoms/icons/files/search.jsx"
import ArrowRight from "../../../atoms/icons/files/arrow-right.jsx"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import BlogMenu from "../blogMenu"
import ContactMenu from "../contactMenu"
import AboutMenu from "../aboutMenu"
import FurnitureMenu from "../furnitureMenu"
import BeddingMenu from "../beddingMenu"
import { CreateLocalLink } from "../../../../lib/util"
import { LinkWrapper } from "../../../../utils/linkWrapper"

const MegaMenu = memo(({ columns, type }) => {
  const data = useStaticQuery(QUERY)

  let tempCols = false

  if (columns && columns.length !== 0) {
    if (type === "bedding") {
      tempCols = 4
    } else {
      tempCols = columns.length + 1
    }
  }

  const cols = tempCols

  const acfOptions = data.localWpGraphQlTmwGeneralSettings.tmw_settings

  let bannerData = {}

  // "type" here seems to be the slug of the menu name
  if (type === "beds") bannerData = acfOptions.bedsCta
  if (type === "mattresses") bannerData = acfOptions.mattressesCta
  if (type === "bedding" || type === "linen")
    return <BeddingMenu links={columns} cta={acfOptions.accessoriesCta} />
  if (type === "furniture")
    return <FurnitureMenu links={columns} cta={acfOptions.furnitureCta} />

  if (type === "about")
    return <AboutMenu links={columns} cta={acfOptions.aboutCta} />
  if (type === "blog") return <BlogMenu links={columns} />
  if (type === "contact") return <ContactMenu />

  if (!cols) return null

  return (
    <MegaMenuWrapper cols={cols}>
      {columns && (
        <Grid
          cols={cols}
          gutter="35px"
          narrowGutter="20px"
          align="flex-start"
          padding="0"
        >
          {bannerData.backgroundImage && (
            <Banner
              fluid={
                bannerData.backgroundImage.imageFile.localFile.childImageSharp
                  .fluid
              }
            >
              <div className="content">
                <h5>{bannerData.title}</h5>
                <p className="tiny">{bannerData.description}</p>
                {bannerData.buttonText && (
                  <Button to={bannerData.buttonUrl}>
                    <SvgIcon
                      SvgComponent={Search}
                      hue="white"
                      size="md"
                      shade="000"
                    />
                    <div>{bannerData.buttonText}</div>
                  </Button>
                )}
              </div>
            </Banner>
          )}
          {columns.map((node, i) => (
            <Column key={i}>
              {node.url !== "#" ? (
                <LinkWrapper
                  to={CreateLocalLink(node.url)}
                  className="column-heading"
                >
                  {node.label}{" "}
                  <SvgIcon SvgComponent={ArrowRight} hue="ruby" shade="060" />
                </LinkWrapper>
              ) : (
                <p className="column-heading">{node.label}</p>
              )}
              {node.childItems &&
                node.childItems.map((childNode, i) => (
                  <LinkWrapper
                    to={CreateLocalLink(childNode.url)}
                    key={`child-${i}`}
                  >
                    {childNode.label}
                  </LinkWrapper>
                ))}
            </Column>
          ))}
        </Grid>
      )}
    </MegaMenuWrapper>
  )
})

export default MegaMenu

const QUERY = graphql`
  {
    localWpGraphQlTmwGeneralSettings {
      tmw_settings {
        bedsCta {
          buttonText
          buttonUrl
          description
          title
          backgroundImage {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        mattressesCta {
          buttonText
          buttonUrl
          description
          title
          backgroundImage {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        furnitureCta {
          backgroundImage {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        aboutCta {
          backgroundImage {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        accessoriesCta {
          backgroundImage {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const MegaMenuWrapper = styled.div`
  left: 0;
  top: 74px;
  padding: 30px;
  @media (max-width: 1245px) {
    padding: 15px;
  }
`
const Column = styled.div`
  a {
    color: #b6c9e3;
    display: block;
    font-weight: 300;
    font-size: 15px;
    padding: 6px 0;
    transition: all 100ms ease-in-out;
    line-height: 1.2;
    &:hover {
      color: #fff;
    }
  }
`
const Banner = styled(BackgroundImage)`
  display: grid;
  align-items: flex-end;
  width: 250px;
  height: 100%;
  min-height: 350px;
  border-radius: 3px;
  overflow: hidden;
  .content {
    background-color: rgba(26, 41, 60, 0.5);
    padding: 20px;
    h5 {
      color: #fff;
      font-size: 1.2rem;
      line-height: 1.2;
    }
    p {
      color: #fff;
      font-weight: 300;
      line-height: 1.2;
      padding: 0 0 10px 0;
    }
  }
`
const Button = styled(LinkWrapper)`
  background: ${({ theme }) => theme.colors.ruby["060"]};
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: 35px 1fr;
  align-items: center;
  justify-items: flex-start;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateY(-2px);
    background: ${({ theme }) => theme.colors.ruby["100"]};
  }
  > div {
    padding: 0 15px;
    font-size: 20px;
  }
  > span {
    padding: 8px;
  }
`
