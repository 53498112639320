import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

import { graphql, useStaticQuery } from "gatsby"

import MegaMenu from "./megaMenu"
import { CreateLocalLink } from "../../../lib/util"
import { isBrowser } from "../../../services/auth"

import LinkWrapper from "../../../utils/linkWrapper"

const closeDelay = 200 // Delay in ms before the menu closes, prevents flickering

const headerMain = ({ isInStoreFrontendParent = false }) => {
  const data = useStaticQuery(QUERY)
  const { menu } = data
  const menuNodes = (menu.nodes[0] && menu.nodes[0].menuStructure) || []

  const [megaOpen, setMegaOpen] = useState(false)
  const [activeMega, setActiveMega] = useState(0)
  const [isInStoreFrontendState, setIsInStoreFrontendState] = useState(false)
  const [hideMainNavigation, setHideMainNavigation] = useState(false)

  useEffect(() => {
    setIsInStoreFrontendState(isInStoreFrontendParent)
    let hideMainNavigation_new = false
    if (isInStoreFrontendParent) {
      if (isBrowser() && window.location.pathname.match(/\/flatlist\/?/)) {
        hideMainNavigation_new = true
      }
    }
    setHideMainNavigation(hideMainNavigation_new)
  }, [isInStoreFrontendParent])

  let timerId = useRef(null)

  const closeMenu = () => {
    clearTimeout(timerId.current)
    timerId.current = setTimeout(() => setMegaOpen(false), closeDelay)
  }
  const openMenu = () => {
    clearTimeout(timerId.current)
    setMegaOpen(true)
  }

  return (
    <MainNavigationWrapper
      hideMainNavigation={hideMainNavigation}
      isInStoreFrontendState={isInStoreFrontendState}
    >
      <NavInner>
        {menuNodes.map((node, i) => {
          const nodeUrl = CreateLocalLink(node.url)
          return (
            <MenuItem
              to={nodeUrl}
              as={nodeUrl ? LinkWrapper : "div"}
              active={megaOpen && activeMega === i ? "true" : "false"}
              key={i}
              onMouseEnter={() => {
                openMenu()
                setActiveMega(i)
              }}
              onMouseLeave={closeMenu}
            >
              {node.label}
            </MenuItem>
          )
        })}
      </NavInner>
      <Mega
        megaOpen={megaOpen}
        onMouseEnter={openMenu}
        onMouseLeave={closeMenu}
      >
        {menuNodes.map((menuNode, menuNode_i) => (
          <MegaInner
            megaOpen={menuNode_i === activeMega}
            key={menuNode_i}
            className={
              menuNode_i === activeMega ? "active-menu" : "hidden-menu"
            }
          >
            <MegaMenu
              columns={menuNode.childItems}
              type={menuNode.label.toLowerCase()}
            />
          </MegaInner>
        ))}
      </Mega>
    </MainNavigationWrapper>
  )
}

export default headerMain

const MenuItem = styled(LinkWrapper)`
  color: white;
  text-decoration: none;
  padding: 20px;
  width: 100%;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  padding: 20px;
  border-radius: 5px 5px 0 0;
  transition: 0.3s all ease-in-out;
  background: ${({ theme, active }) =>
    active === "true" && theme.colors.ruby["080"]};
`

const Mega = styled.div`
  background: ${({ theme }) => theme.colors.midnight[100]};
  max-height: ${({ megaOpen }) => (megaOpen ? "100vh" : "0")};
  height: auto;
  opacity: ${({ megaOpen }) => (megaOpen ? "1" : "0")};
  z-index: ${({ megaOpen }) => (megaOpen ? "99" : "-1")};
  transition: max-height 0.3s ease-in-out;
  position: absolute;
  width: 1240px;
  margin-left: calc(50% - 620px);
  overflow: hidden;
  border-top: 2px solid
    ${({ theme, megaOpen }) => megaOpen && theme.colors.ruby["080"]};

  @media (max-width: 1245px) {
    width: 100%;
    margin: 0;
  }
`

const MainNavigationWrapper = styled.div`
  display: ${({ isInStoreFrontendState, hideMainNavigation }) =>
    isInStoreFrontendState && hideMainNavigation ? "none" : "block"};
  background: ${({ isInStoreFrontendState, theme }) =>
    isInStoreFrontendState
      ? theme.colors.ocean["080"]
      : theme.colors.midnight["060"]};
  color: ${({ theme }) => theme.colors.white["000"]};
  padding: 10px 0 0 0;
  position: relative;
  .column-heading {
    transition: 0.2s all ease-in-out;
    color: #fff;
    margin-bottom: 8px;
    font-size: 1.25rem;
    font-weight: 300;
  }
  a.column-heading {
    &:hover {
      color: ${({ theme }) => theme.colors.ruby["060"]};
    }
  }
`
const NavInner = styled.div`
  display: grid;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  padding: 0;
  width: 100%;
  text-align: center;
  grid-template-columns: repeat(9, auto);
  align-items: flex-end;
  justify-items: center;
`
const MegaInner = styled.div`
  &.hidden-menu {
    display: none;
  }
  height: auto;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  padding: 0;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 0 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    padding: 0 10px;
  }
`

const QUERY = graphql`
  {
    menu: allDataJson {
      nodes {
        menuStructure {
          childItems {
            childItems {
              label
              url
            }
            label
            url
          }
          label
          url
        }
      }
    }
  }
`
