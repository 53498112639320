import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import { injectScript } from "../../utils/injectScript"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import searchIcon from "../../images/icons/search-dark.svg"
import LocationPin from "../../zzz/atoms/icons/files/location-pin.jsx"
const LocationSearch = ({ className = "", setUserCoordinates }) => {
  const [address, setAddress] = useState("")
  const [scriptLoading, setScriptLoading] = useState(true)

  // Fetch address from WC
  useEffect(() => {
    // Load script once
    const scriptParams = {
      id: "google_maps_api_script",
      parentElement: "head",
      src: `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_PLACES_API_KEY}&libraries=places`,
      onLoad: googleApiInitialize,
    }
    injectScript(scriptParams)
  }, [])
  const googleApiInitialize = () => setScriptLoading(false)

  const handleSelect = (address) => {
    setAddress(address)
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0])
          .then((latLng) => {
            setUserCoordinates({
              latitude: latLng?.lat,
              longitude: latLng?.lng,
            })
          })
          .catch((error) => {
            console.error("Get Lat/Long error", error)
          })
      })
      .catch((error) => {
        console.error("Get address object error", error)
      })
  }

  const getBrowserLocation = () => {
    try {
      // Try HTML5 geolocation.
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const browserPosition = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }
            console.log("User Location found: ", browserPosition)
            setUserCoordinates(browserPosition)
          },
          () => {
            console.log("User denied location")
          }
        )
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  if (scriptLoading) {
    return null
  }
  return (
    <PlacesAutocomplete
      className={className}
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
      highlightFirstSuggestion
      shouldFetchSuggestions={address.length >= 2}
      searchOptions={{
        componentRestrictions: { country: "za" },
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <LocationSearchContainer>
          <StyledInputLabel>
            Enter your address to find the closest store or search by province,
            city or store name
          </StyledInputLabel>
          <SearchContainer>
            <SearchInputContainer>
              <StyledSearchInput
                {...getInputProps({
                  placeholder: "Search for your address",
                  autoFocus: true,
                })}
              />
              <SearchDropdownContainer
                className={suggestions.length == 0 && "collapsed"}
              >
                {loading && (
                  <SearchDropdownItemStyling>
                    <div>Loading...</div>
                  </SearchDropdownItemStyling>
                )}
                {suggestions.map((suggestion, suggest_i) => (
                  <SearchDropdownItemStyling key={suggest_i}>
                    <div {...getSuggestionItemProps(suggestion)}>
                      <span>{suggestion.description}</span>
                    </div>
                  </SearchDropdownItemStyling>
                ))}
              </SearchDropdownContainer>
            </SearchInputContainer>
            <Button onClick={getBrowserLocation}>
              <div>Use my location</div>
              <SvgIcon
                SvgComponent={LocationPin}
                hue="danger"
                shade="fontColor"
                size="md"
              />
            </Button>
          </SearchContainer>
        </LocationSearchContainer>
      )}
    </PlacesAutocomplete>
  )
}

export default LocationSearch

// ======================
// 	     STYLES
// ======================

const LocationSearchContainer = styled.div`
  margin: 40px 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: fit-content;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin: 40px 20px;
  }
`
const StyledInputLabel = styled.p`
  color: #3e4b5c;
  font-family: Roboto;
  font-size: 16px;
  width: 100%;
  margin-bottom: 15px;
`
const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    flex-direction: column;
  }
`
const StyledSearchInput = styled.input`
  padding: 10px;
  border: 1px solid #3e4b5c;
  border-radius: 3px;
  background: aliceblue;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  height: 50px;
  color: #3e4b5c;
  overflow: hidden;
  padding-right: 40px;
  width: 100%;

  &:focus {
    outline: none !important;
    border-radius: 3px 3px 0 0;
  }
`

const Button = styled.span`
  height: 50px;
  margin: 0;
  margin-left: auto;
  background: #fd3237;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  min-width: fit-content;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(2px);
    cursor: pointer;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    margin-top: 10px;
  }

  > div {
    padding: 0;
    margin: 0 40px;
    margin-right: 30px;

    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      margin: 0 20px;
    }

    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  > span {
    padding: 8px;
    background: #da2e32;
  }
`
const SearchDropdownContainer = styled.div`
  z-index: 999;
  position: absolute;
  width: 100%;
  border-left: 1px solid #3e4b5c;
  border-right: 1px solid #3e4b5c;
  border-bottom: 1px solid #3e4b5c;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 10px 10px -10px white;
  background-color: white;

  &.collapsed {
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
`

const SearchDropdownItemStyling = styled.div`
  background-color: #ffffff;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  line-height: 18px;
  min-height: 50px;

  &:nth-of-type(odd) {
    background: #e5e5e5;
  }

  &:hover {
    background-color: #3e4b5c;
    color: white;
    cursor: pointer;
  }
`
const SearchInputContainer = styled.div`
  width: 100%;
  margin-right: 30px;
  position: relative;
`
