import React, { useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { LinkWrapper as Link } from "../../../../utils/linkWrapper"
import { CreateLocalLink } from "../../../../lib/util"
import SvgIcon from "../../../atoms/icons/svg-icon"
import ArrowRight from "../../../atoms/icons/files/arrow-right.jsx"

const BeddingMenu = ({ links, cta }) => {
  const fluidImage =
    cta.backgroundImage.imageFile.localFile.childImageSharp.fluid

  const [subMenuOpen, setSubMenuOpen] = useState(false)
  const [activeSubMenu, setActiveSubMenu] = useState(0)
  const separateMenus = links.filter((node) => node.url === "#")
  const subMenus = links.filter((node) => node.url !== "#")

  const activeItem = subMenus[activeSubMenu]

  return (
    <Wrap>
      <ImgContainer key={"row-0"}>
        <Img fluid={fluidImage} className="image" />
      </ImgContainer>
      <Stack key={"row-1"} className={"submenu-container"}>
        {subMenus.map((node, i) => {
          return (
            <BeddingExpandingMenuItem
              to={CreateLocalLink(node.url)}
              index={i}
              active={subMenuOpen && activeSubMenu === i ? "true" : "false"}
              key={i}
              onMouseEnter={() => {
                setSubMenuOpen(true)
                setActiveSubMenu(i)
              }}
              onMouseLeave={() => setSubMenuOpen(false)}
            >
              {node.label}
              <SvgIcon
                key={i}
                SvgComponent={ArrowRight}
                hue="ruby"
                shade="060"
              />
            </BeddingExpandingMenuItem>
          )
        })}
      </Stack>
      <Stack key={"row-2"} className={`${subMenuOpen && "submenu-active"}`}>
        {subMenuOpen && (
          <SubMenu
            subMenuOpen={subMenuOpen}
            onMouseEnter={() => setSubMenuOpen(true)}
            onMouseLeave={() => setSubMenuOpen(false)}
          >
            <EntranceStyling>
              <SubMenuContainerStyling
                subMenuOpen={subMenuOpen}
                className={"sub-menu-container"}
                onClick={() => setSubMenuOpen(false)}
              >
                <Link
                  key={`Link-0`}
                  to={CreateLocalLink(activeItem.url)}
                  className="column-heading submenu-heading submenu-item"
                >
                  {activeItem.label}
                </Link>
                {activeItem.childItems.map((node, i) => {
                  return (
                    <Link
                      to={CreateLocalLink(node.url)}
                      key={i}
                      className="sub submenu-item"
                    >
                      {node.label}
                    </Link>
                  )
                })}
              </SubMenuContainerStyling>
            </EntranceStyling>
          </SubMenu>
        )}
      </Stack>
      <Stack key={"row-3"} />
      <Stack key={"row-4"}>
        {separateMenus.map((node, i) => {
          return (
            <div className={"column-wrap"} key={i}>
              <div key={i} className="column-heading">
                {node.label}
              </div>
              {node.childItems.map((childNode, i) => (
                <Link
                  to={CreateLocalLink(childNode.url)}
                  key={`separateMenus-${i}`}
                  className="sub"
                >
                  {childNode.label}
                </Link>
              ))}
            </div>
          )
        })}
      </Stack>
    </Wrap>
  )
}

export default BeddingMenu

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.2fr 1.7fr 1fr 1fr;
  padding-left: 40px;
  padding-right: 40px;
  grid-gap: 0px;
  height: 460px;
  @media (max-width: 1245px) {
    grid-template-columns: 1fr 1.4fr 1.5fr 0fr 1fr;
  }
`
const ImgContainer = styled.div`
  padding-right: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 460px;
  img {
    border-radius: 3px;
  }
  .image {
    height: 380px;
  }
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  div.column-wrap {
    display: flex;
    flex-direction: column;
  }
  div.column-heading {
    padding-left: 6px;
  }
  &.submenu-container {
    justify-content: space-evenly;
  }
  &.submenu-active {
    background: ${({ theme }) => theme.colors.midnight["080"]};
  }
  a {
    color: white;
    font-size: 1.25rem;
    padding: 6px;
    font-weight: 300;
  }
  a.sub {
    font-size: 15px;
    font-weight: 100;
    color: #b6c9e3;
    transition: all 200ms ease-in-out;
    &:hover {
      color: #fff;
    }
  }
  .submenu-heading {
    font-weight: 300;
    padding-bottom: 0px;
  }
  .submenu-item {
    padding-left: 20px;
  }
`

const BeddingExpandingMenuItem = styled(Link)`
  color: white;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  border-radius: 5px 0 0 5px;
  background: ${({ theme, active }) =>
    active === "true" && theme.colors.ruby["080"]};
`

const SubMenu = styled.div`
  opacity: ${({ subMenuOpen }) => (subMenuOpen ? "1" : "0")};
  z-index: ${({ subMenuOpen }) => (subMenuOpen ? "99" : "-1")};
  transform: all 0.3s ease-in-out;
  position: absolute;
  overflow: hidden;
  width: auto;
  .sub-menu-container {
    display: flex;
    flex-direction: column;
    height: 390px;
    width: auto;
  }
`
const EntranceStyling = styled.div`
  animation: slidein 0.5s ease-in-out;
  @keyframes slidein {
    from {
      transform: translateX(-100px);
      opacity: 0;
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`
const SubMenuContainerStyling = styled.div`
  transform: ${({ subMenuOpen }) =>
    subMenuOpen ? "translateX(0)" : "translateX(-20px)"};
  opacity: ${({ subMenuOpen }) => (subMenuOpen ? "1" : "0")};
  transform: all 0.3s ease-in-out;
`
