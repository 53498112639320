import React, { useState, useEffect } from "react"

import HeaderSkinny from "../../molecules/headerSkinny"
import HeaderMain from "../../molecules/headerMain"
import MainNavigation from "../../molecules/mainNavigation"

import { isInStoreFrontend } from "../../../services/auth"

const DesktopHeader = ({ location }) => {
  const [isInStoreFrontendState, setIsInStoreFrontendState] = useState(false)

  useEffect(() => {
    if (!isInStoreFrontendState) {
      if (isInStoreFrontend()) {
        setIsInStoreFrontendState(true)
      }
    }
  }, [])

  return (
    <div className="desktop">
      <HeaderSkinny isInStoreFrontendParent={isInStoreFrontendState} />
      <HeaderMain
        location={location}
        isInStoreFrontendParent={isInStoreFrontendState}
      />
      <MainNavigation isInStoreFrontendParent={isInStoreFrontendState} />
    </div>
  )
}
export default DesktopHeader
