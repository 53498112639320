import React, { memo, useState } from "react"
import styled from "styled-components"
import { LinkWrapper as Link } from "../../utils/linkWrapper"

import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ChevronUp from "../../zzz/atoms/icons/files/chevron-up.jsx"
import ChevronDown from "../../zzz/atoms/icons/files/chevron-down.jsx"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ComfortTrialItems = memo(({ comfortTrialItems = [] }) => {
  // Check if any item does not currently qualify
  const somethingDoesNotQualify = comfortTrialItems.some(
    (it) => !it.comfortTrialQualified
  )

  return (
    <DropDown>
      <InnerLineItems>
        {somethingDoesNotQualify ? (
          <p>
            Your purchase does not currently qualify for our 100 Night In-Home
            Comfort Trial, please add a matching{" "}
            <Link to="/bedroom-accessories/mattress-protectors/" as="span">
              mattress protector
            </Link>{" "}
            to your bed purchase to qualify.
          </p>
        ) : (
          <p>
            You successfully qualify for our 100 Night In-Home Comfort Trial!
          </p>
        )}
        {comfortTrialItems.map((item, indx) => (
          <InnerLineItem
            key={indx}
            className={indx == comfortTrialItems.length - 1 && "last"}
          >
            <span>{item.productName}</span>{" "}
            {item.comfortTrialQualified ? <CheckMark /> : <CrossMark />}
          </InnerLineItem>
        ))}
      </InnerLineItems>
    </DropDown>
  )
})

export default ComfortTrialItems

const DropDown = styled.div`
  background: white;
  padding: 10px 30px;
  box-shadow: 0 10px 15px -7px rgba(119, 132, 142, 0.19);
`
const Label = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 16px;
  align-items: center;
  margin: 10px 0;
`
const InnerLineItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  line-height: 3;
  font-size: 16px;
  font-weight: 300;
  transition: 0.3s all ease-in-out;
  p {
    font-size: 12px;
    margin: 10px 0;
  }
`
const InnerLineItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #cfd5db;
  &.last {
    border-bottom: 1px solid #cfd5db;
  }
  font-size: 14px;
  font-style: italic;
  span {
    line-height: 2;
    padding-right: 15px;
  }
`
const CheckMark = styled.div`
  display: inline-block;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-width: 3px;
  margin-right: 15px;
  margin-bottom: 5px;
  height: 16px;
  width: 8px;

  border-color: #78b13f;
  transform: rotate(45deg);
`
const CrossMark = styled.div`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 25px;
  margin-right: 10px;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;
    top: 50%;
    left: 0;
    background: #fd3237;
    border-radius: 5px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`
