import React, { useEffect, useState, memo, useRef } from "react"
import styled from "styled-components"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Counter = memo(
  ({
    value = 1,
    onChange = () => {},
    onFocusChange = () => {},
    focus = false,
    min = 1,
    max = 99,
    disabled = false,
    isReadOnly = false,
  }) => {
    const valueComponentRef = useRef(null)

    const inc = () => inc_or_dec(1)
    const dec = () => inc_or_dec(-1)

    const inc_or_dec = (change_val) => {
      let new_amount = value
      if (typeof new_amount !== "number" || Number.isNaN(new_amount)) {
        new_amount = min
      } else {
        new_amount += change_val
        if (new_amount < min) {
          new_amount = min
        } else if (new_amount > max) {
          new_amount = max
        }
      }
      onChange(new_amount)
    }

    // focus on value input right after render
    useEffect(() => {
      if (focus) {
        valueComponentRef.current.focus()
      }
    }, [focus])

    const handleChange = (changeObj) => {
      const newValue = changeObj && changeObj.target && changeObj.target.value
      const num = parseInt(newValue)

      if (typeof num !== "number" || Number.isNaN(num)) {
        // match deleteContentForward for the delete key
        // match deleteContentBackward for the backspace key
        const nativeEvent = changeObj && changeObj.nativeEvent
        if (nativeEvent && nativeEvent.inputType.startsWith("deleteContent")) {
          onChange(0)
          return
        }
        return
      } else if (num < min) {
        onChange(min)
        return
      } else if (num > max) {
        onChange(max)
        return
      } else {
        onChange(num)
        return
      }
    }

    const handleBlur = () => {
      if (typeof amount !== "number" || Number.isNaN(value)) {
        onChange(1)
      }
      onFocusChange(false)
    }

    const handleFocus = () => {
      onFocusChange(true)
    }

    return (
      <Container>
        <Button disabled={disabled || value <= 1} onClick={dec}>
          -
        </Button>
        <Value
          ref={valueComponentRef}
          isReadOnly={isReadOnly}
          readonly={isReadOnly}
          disabled={disabled}
          type="text"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        <Button disabled={disabled || value >= max} onClick={inc}>
          +
        </Button>
      </Container>
    )
  }
)

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const Container = styled.div`
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`

const Button = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: white;
  border: none;
  padding: 0;
`

const Value = styled.input`
  ${({ isReadOnly }) => isReadOnly && "pointer-events: none;"};
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  max-width: 40px;
  text-align: center;
  border: none;
`

export default Counter
