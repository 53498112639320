import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import ArrowRight from "../../../atoms/icons/files/arrow-right"
import { LinkWrapper as Link } from "../../../../utils/linkWrapper"

const FeaturedCard = ({ title, uri, fluid, subTitle, noLink, onClick }) => {
  const data = useStaticQuery(PLACEHOLDER)
  const As = noLink ? Wrap : StyledLink
  return (
    <As to={uri} onClick={onClick}>
      <Img
        fluid={fluid || data.file.childImageSharp.fluid}
        objectPosition="top center"
        imgStyle={{ objectPosition: "top center" }}
      />
      <div>
        <h3>
          <span
            dangerouslySetInnerHTML={{
              __html: `${title.substring(0, 80)}${
                title.length > 80 ? "..." : ""
              } `,
            }}
          />
          <ArrowRight fill="red" size="md" />
        </h3>
        {subTitle && <p>{subTitle}</p>}
      </div>
    </As>
  )
}

export default FeaturedCard

const Wrap = styled.div`
  background: white;
  border-radius: 4px;
  display: block;
  overflow: hidden;
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  h3 {
    color: ${({ theme }) => theme.colors.midnight["080"]} !important;
  }
  .gatsby-image-wrapper {
    background-position: top center;
    height: 300px;
    width: 100%;
  }
  div {
    padding: 15px;
  }
  p {
    color: #9ba5ae;
    font-family: Roboto;
    font-size: 14px;
    line-height: 21px;
  }
`

const StyledLink = styled(Link)`
  background: white;
  border-radius: 4px;
  display: block;
  overflow: hidden;
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  &:hover {
    transform: translateY(-3px);
  }
  h3 {
    color: ${({ theme }) => theme.colors.midnight["080"]} !important;
    font-size: 18px;
    line-height: 1.4;
  }
  .gatsby-image-wrapper {
    height: 205px;
    width: 100%;
  }
  div {
    padding: 15px;
  }
  p {
    color: #9ba5ae;
    font-family: Roboto;
    font-size: 14px;
    line-height: 21px;
  }
`
const PLACEHOLDER = graphql`
  {
    file(relativePath: { eq: "team-image-placeholder.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
