import React from "react"
import styled from "styled-components"
import { LinkWrapper as Link } from "../../../../utils/linkWrapper"
import Img from "gatsby-image"
import { CreateLocalLink } from "../../../../lib/util"
import SvgIcon from "../../../atoms/icons/svg-icon"
import ArrowRight from "../../../atoms/icons/files/arrow-right.jsx"

const FurnitureMenu = ({ links, cta }) => {
  const fluidImage =
    cta.backgroundImage.imageFile.localFile.childImageSharp.fluid
  return (
    <Wrap>
      <Img fluid={fluidImage} className="image" />
      <Links>
        {links.map((node, i) => {
          const nodeUrl = CreateLocalLink(node.url)
          return node.childItems ? (
            <Stack key={i}>
              {node.url === "#" ? (
                <div className="column-heading">{node.label}</div>
              ) : (
                <Link to={nodeUrl} className="column-heading">
                  {node.label}
                  {node.url !== "#" && (
                    <SvgIcon SvgComponent={ArrowRight} hue="ruby" shade="060" />
                  )}
                </Link>
              )}
              {node.childItems.map((childnode, i) => {
                const childnodeUrl = CreateLocalLink(childnode.url)
                return (
                  <Link
                    to={childnodeUrl}
                    as={childnodeUrl ? Link : "div"}
                    key={i}
                    className="sub"
                  >
                    {childnode.label}
                  </Link>
                )
              })}
            </Stack>
          ) : (
            <Link
              to={nodeUrl}
              as={nodeUrl ? Link : "div"}
              key={i}
              className="column-heading"
            >
              {node.label}
            </Link>
          )
        })}
      </Links>
    </Wrap>
  )
}

export default FurnitureMenu

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  // grid-template-columns: repeat(5, 1fr);
  padding: 40px;
  grid-gap: 50px;
  min-height: 400px;
  img {
    border-radius: 3px;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    grid-template-columns: 1fr;
    .image {
      display: none;
    }
  }
`

const Stack = styled.div`
  a.sub {
    font-weight: 100;
    font-size: 15px;
    padding: 6px 0;
    color: #b6c9e3;
    transition: all 200ms ease-in-out;
    &:hover {
      color: #fff;
    }
  }
`

const Links = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  a {
    display: block;
    color: white;
  }
`
