import React from "react"
import styled from "styled-components"

const Inner = ({ children, className = "" }) => (
  <InnerWrapper className={"inner " + className}>{children}</InnerWrapper>
)

export default Inner

const InnerWrapper = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  padding: 0 40px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    padding: 0 10px;
  }
  .error {
    text-align: center;
    padding: 10px;
    background: pink;
    border-color: red;
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    a {
      color: #0988bb;
      text-decoration: underline;
    }
  }
  .message {
    text-align: center;
    padding: 10px;
    border-color: #0988bb;
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    a {
      color: #0988bb;
      text-decoration: underline;
    }
  }
`
