import React from "react"
import styled from "styled-components"
import LinkWrapper from "../../utils/linkWrapper"

const SearchDropdown = ({
  title,
  searchResults,
  searchResultClicked,
  searchContainerFocus,
  client,
  searchQuery,
  searchResponse,
}) => {
  return (
    <>
      {searchResults.length > 0 && searchContainerFocus && (
        <>
          <h4>{title}</h4>
          {searchResults.map((hit) => {
            return !hit?.id?.raw ? null : (
              <ElasticSearchHitStyledLinkWrapper
                id={`search-result-hit-${hit.id.raw}`}
                key={hit.id.raw}
                to={hit.permalink.raw}
                onClick={() => {
                  if (searchResponse?.info?.meta?.request_id) {
                    const searchAnalyticsObject = {
                      query: searchQuery,
                      documentId: hit.id.raw,
                      requestId: searchResponse?.info?.meta?.request_id,
                    }
                    client.click(searchAnalyticsObject).catch((error) => {
                      console.error(`search click error: ${error}`)
                    })
                  }
                }}
                className={`${
                  searchResultClicked === `search-result-hit-${hit.id.raw}`
                    ? `clicked`
                    : `not-clicked`
                } ${
                  hit.result_type.raw === "product" ? `product` : `not-product`
                }`}
              >
                {hit.result_type.raw === "product" &&
                  hit.featured_image.raw && (
                    <ThumbnailImgStyling
                      src={hit.featured_image.raw}
                      alt={hit.title.raw}
                    />
                  )}
                <HitTitleStyling>{`${hit.title.raw}`}</HitTitleStyling>
              </ElasticSearchHitStyledLinkWrapper>
            )
          })}
        </>
      )}
    </>
  )
}

export default SearchDropdown

// ==========
// 	 STYLES
// ==========
const ElasticSearchHitStyledLinkWrapper = styled(LinkWrapper)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #3e4b5c;
  font-size: 1rem;
  overflow: hidden;
  &:hover {
    box-shadow: 0 0 25px 0px rgba(0, 0, 0, 0.4);
    position: relative;
    z-index: 101;
  }
  &.clicked {
    background-color: #d9e4f3;
  }
  &.not-clicked {
    background-color: white;
  }
  &:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  &.product {
    padding: 1px 20px;
  }
`
const ThumbnailImgStyling = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;
  margin: auto 0;
  overflow: hidden;
`
const HitTitleStyling = styled.div`
  color: #223247 !important;
  margin-left: 20px;
`
