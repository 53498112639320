import React, { useContext } from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import BranchContext from "../../context/BranchContext"

import { shippingClassGet } from "../../lib/util"
import ArrowRight from "../../zzz/atoms/icons/files/arrow-right.jsx"
import LocationPin from "../../zzz/atoms/icons/files/location-pin.jsx"
import FeatherCheck from "../../zzz/atoms/icons/files/feather-check.jsx"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import Delivery from "../../zzz/atoms/icons/files/delivery.jsx"
import Info from "../../zzz/atoms/icons/files/info.jsx"
import Tooltip from "../ProductSingle/Tooltip.jsx"

const BranchLocation = ({
  selected = false,
  className = "",
  branchName,
  address,
  branchMoneyworksId,
  image,
  distance,
  branchShippingClass,
  TsCs,
  productSelected = false,
}) => {
  const { setSelectedBranch, setBranchSelectPopupOpen } = useContext(
    BranchContext
  )

  let badgeText = "AVAILABLE"
  let badgeColor = "secondary"
  if (branchShippingClass) {
    badgeText = shippingClassGet(branchShippingClass, "text")
    if (branchShippingClass === "fast-track-shipping") {
      badgeColor = "teal"
    } else {
      badgeColor = "primary"
    }
  }

  return (
    <StyledBranchLocationContainer className={className}>
      <BranchImageContainer>
        <Img
          fixed={image?.imageFile?.localFile?.childImageSharp?.fixed}
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
        />
      </BranchImageContainer>
      <BranchDetailsContainer>
        <BranchNameContainer>
          <StyledBranchNameHeading
            dangerouslySetInnerHTML={{ __html: branchName }}
          />
          {branchShippingClass == null ? (
            <div> </div>
          ) : (
            <BadgeContainer className="show-mobile">
              <Badge color={badgeColor}>{badgeText}</Badge>
            </BadgeContainer>
          )}
        </BranchNameContainer>
        <StyledBranchAddress>{address}</StyledBranchAddress>
        <BranchIconsContainer>
          {!distance || distance == Infinity ? null : (
            <>
              <SvgIcon SvgComponent={LocationPin} hue="ruby" shade="080" />
              <div>{`${distance} km`}</div>
            </>
          )}
        </BranchIconsContainer>
        {productSelected && (
          <Conditions>
            <LeftConditionContainer>
              <ConditionSvgContainer>
                <SvgIcon SvgComponent={Delivery} hue="tusk" shade="080" />
              </ConditionSvgContainer>
              <div
                style={{
                  color: shippingClassGet(branchShippingClass, "color"),
                }}
              >
                {`Delivery Estimate: ${shippingClassGet(
                  branchShippingClass,
                  "delay"
                )} business days (main city hubs)`}
              </div>
              <Tooltip
                text={TsCs}
                background={"#1a293c"}
                minWidth="200px"
                className="tooltip"
              >
                <Circle>
                  <SvgIcon hue="white" shade="000" SvgComponent={Info} />
                </Circle>
              </Tooltip>
            </LeftConditionContainer>
            <RightConditionContainer>*E&OE*</RightConditionContainer>
          </Conditions>
        )}
      </BranchDetailsContainer>
      <BadgeAndButtonContainer>
        {branchShippingClass == null ? (
          <div> </div>
        ) : (
          <BadgeContainer className="show-desktop">
            <Badge color={badgeColor}>{badgeText}</Badge>
          </BadgeContainer>
        )}
        <Button
          className={selected ? "selected-branch-button" : ""}
          onClick={() => {
            if (branchMoneyworksId) {
              setSelectedBranch(branchMoneyworksId)
              setBranchSelectPopupOpen(false)
            }
          }}
        >
          <div>{selected ? "Selected" : "Select"}</div>
          <SvgIcon SvgComponent={ArrowRight} hue="danger" shade="fontColor" />
        </Button>
        <MobileButton
          onClick={() => {
            if (branchMoneyworksId) {
              setSelectedBranch(branchMoneyworksId)
              setBranchSelectPopupOpen(false)
            }
          }}
          className={selected ? "selected-branch-button" : ""}
        >
          <SvgIcon
            SvgComponent={FeatherCheck}
            hue={selected ? "white" : "midnight"}
            shade="000"
          />
        </MobileButton>
      </BadgeAndButtonContainer>
    </StyledBranchLocationContainer>
  )
}

export default BranchLocation

const colors = {
  primary: { background: "#FCDBA2", border: "#DA8D08", text: "#DA8D08;" },
  secondary: { background: "green", border: "purple", text: "white" },
  tertiary: { background: "#efb0b3", border: "#d91a1f", text: "#d91a1f" },
  teal: { background: "#0988bb", border: "#0988bb", text: "white" },
}

const getColor = (type) => ({ color }) => colors[color][type]
// ===============
//     STYLES
// ===============

const StyledBranchLocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding: 30px 50px;

  &.selected-branch {
    border: 1px solid #0988bb;
  }

  &:nth-of-type(even) {
    background: #e5e5e5;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 30px 20px;
  }
`

const BranchImageContainer = styled.div`
  width: 142px;
  min-width: 142px;
  height: 142px;
  margin-right: 50px;
  align-self: center;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
`

const BranchDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 142px;
`

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 130px;
  min-width: fit-content;

  &.show-desktop {
    @media (max-width: 1440px) {
      display: none;
    }
  }

  &.show-mobile {
    @media (min-width: 1441px) {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    padding-left: 0;
    margin: 10px 0;
    width: fit-content;
  }
`

const BadgeAndButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding-left: 50px;
  width: fit-content;
  min-width: fit-content;

  @media (max-width: 1440px) {
    padding-left: 25px;
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    padding-left: 0;
  }
`

const Badge = styled.div`
  width: fit-content;
  min-width: fit-content;
  border-radius: 2px;
  text-transform: uppercase;
  background: ${getColor("background")};
  color: ${getColor("text")};
  padding: 0px 15px;
  top: 0px;
  right: 0px;
  font-size: 12px;
  overflow: hidden;
  max-height: 18px;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    top: -20px;
  }

  // @media (max-width: ${({ theme }) => theme.breakTiny}) {
  //   font-size: 10px;
  // }
`

const StyledBranchNameHeading = styled.h3`
  font-size: 31px;
  font-weight: bold;
  font-family: Roboto;
  display: inline-block;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 28px;
  }

  // @media (max-width: ${({ theme }) => theme.breakTiny}) {
  //   font-size: 20px;
  // }
`

const StyledBranchAddress = styled.p`
  font-size: 15px;
  font-family: Roboto;

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    padding-right: 10px;
  }
`
const Button = styled.span`
  height: 35px;
  margin: 0;
  margin-left: 50px;
  background: #fd3237;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  width: 140px;
  min-width: 140px;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(2px);
    cursor: pointer;
  }

  @media (max-width: 1240px) {
    display: none;
  }

  &.selected-branch-button {
    background: #3e4b5c;
    > span {
      display: none;
    }
    &:hover {
      transform: none;
    }
  }

  > div {
    padding: 0;
    margin: 0 30px;
    margin-right: 20px;
  }

  > span {
    padding: 5px;
    background: #da2e32;
    height: auto;
  }

  @media (max-width: 1440px) {
    margin-left: 25px;
  }
`
const BranchIconsContainer = styled.div`
  margin-top: auto;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  margin-left: -5px;
`

const BranchNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 400px;

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const MobileButton = styled.div`
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: #3e4b5c;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1241px) {
    display: none;
  }

  &.selected-branch-button {
    background-color: #3e4b5c;
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-right: 10px;
  }
`

const Conditions = styled.div`
  color: #3e4b5c;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    > span.icon:first-child {
      margin-right: 10px;
    }
    > div:last-child {
      margin-left: 10px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    justify-content: space-between;
  }
`
const LeftConditionContainer = styled.div`
  .tooltip {
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      display: none;
    }
  }
`

const RightConditionContainer = styled.div`
  color: #9ba5ae;
  margin-left: 10px;
`

const Circle = styled.div`
  border-radius: 200px;
  background: #9ba5ae;
  transform: scale(0.8);
`
const ConditionSvgContainer = styled.div`
  padding-right: 10px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    display: none;
  }
`
