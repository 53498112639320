import React, { useEffect, useContext, useState, useMemo } from "react"
import styled from "styled-components"
import { getUser } from "../../services/auth"
import AuthContext from "../../context/AuthContext"
import CartContext from "../../context/CartContext"
import { postPromoCoupon, deletePromoCoupon } from "../../lib/woocommApi"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import Cross from "../../zzz/atoms/icons/files/cross.jsx"
import MenuLoader from "../../zzz/organisms/menuLoader"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const addPromoIcon = { hue: "white", shade: "000" }
const removePromoIcon = { hue: "white", shade: "000" }

const Coupon = ({ small, onCouponAdded = () => {} }) => {
  const [loading, setLoading] = useState(false)
  const [apiMessage, setApiMessage] = useState("")
  const [apiError, setApiError] = useState("")
  const [value, setValue] = useState("")
  const { loggedInState, performLogout } = useContext(AuthContext)
  const { getCart, cartContents } = useContext(CartContext)

  // Convenience variable, pulls coupons from the cart contents
  const coupons = useMemo(() => {
    let coupons =
      (cartContents && cartContents.map((it) => Object.keys(it.coupons))) || []
    return coupons.length > 0 ? coupons[0] : []
  }, [cartContents])

  // Attempts to make an API call to add a coupon to the cart
  const handleAdd = () => {
    if (value.length > 0) {
      const { token } = getUser()
      setLoading(true)
      setApiMessage("")
      setApiError("")
      postPromoCoupon(value, token, performLogout)
        .then((resp) => {
          if (resp && resp.message) {
            setApiMessage(resp.message)
            onCouponAdded()
          }
          setLoading(false)
        })
        .catch((error) => {
          if (error && error.message) {
            setApiError(error.message)
          }
          setLoading(false)
        })
      setValue("")
    }
  }

  // Attempts to make an API call to remove a coupon from the cart
  const handleRemove = (coup) => {
    if (coup.length > 0) {
      const { token } = getUser()
      setLoading(true)
      setApiMessage("")
      setApiError("")
      deletePromoCoupon(coup, token, performLogout)
        .then((resp) => {
          if (resp && resp.message) {
            setApiMessage(resp.message)
            onCouponAdded()
          }
          setLoading(false)
        })
        .catch((error) => {
          if (error && error.message) {
            setApiError(error.message)
          }
          setLoading(false)
        })
      setValue("")
    }
  }

  return (
    <Wrap className={small && "small"}>
      <Flex>
        {coupons.map((c) => (
          <Badge key={c}>
            <Text>{c.toUpperCase()}</Text>
            <SvgIcon
              size="sm"
              SvgComponent={Cross}
              onClick={() => handleRemove(c)}
              {...removePromoIcon}
            />
          </Badge>
        ))}
      </Flex>
      <Container small={small}>
        <Button open={true}>
          <div className="text">Promo Code</div>
          <InputWrap
            onSubmit={(e) => {
              e.preventDefault()
              handleAdd()
            }}
          >
            {loading ? (
              <MenuLoader color="#1A293C" center />
            ) : (
              <Input
                type="text"
                placeholder="Enter your promo code here"
                value={value}
                onChange={(e) => {
                  e.persist()
                  setValue(e.target.value)
                }}
              />
            )}
          </InputWrap>
          {value && (
            <div className="apply" onClick={handleAdd}>
              Apply
            </div>
          )}
        </Button>
      </Container>
      {apiMessage && <div className="message">{apiMessage}</div>}
      {apiError && <div className="error">{apiError}</div>}
    </Wrap>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Text = styled.div`
  padding-left: 10px;
`

const Badge = styled.div`
  background: #0998d2;
  color: #ffffff;
  border-radius: 7px;
  border-bottom: dotted;
  border-top: dotted;
  margin-right: 5px;
  display: flex;
  align-items: center;
  overflow: hidden;
  span.icon {
    cursor: pointer;
    svg {
      transition: 0.2s all ease-in-out;
    }
    &:hover {
      svg {
        transform: scale(1.3);
      }
    }
  }
`
const Wrap = styled.div`
  div.error,
  div.message {
    text-align: center;
    margin: 10px 0;
  }

  div.error {
    color: #fd3237;
  }

  div.message {
    color: #48b6dd;
  }
  width: 100%;
  &.small {
    * {
      font-size: 14px;
    }
    input {
      padding: 5px;
    }
    span.icon {
      padding: 0 5px;
    }
  }
`
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`

const Container = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  ${({ small }) => small && "font-size: 14px;"}
`
const Button = styled.div`
  background: white;
  border-radius: 3px;
  border: 2px solid #bbc1c6;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ open }) => open && "border: 1px solid #1A293C;"}
  transition: 0.2s all ease-in-out;
  ${({ open }) => !open && "cursor: pointer;"}
  ${({ open }) => open && "width:100%;"}
	div.text {
    height: 100%;
    ${({ small }) => small && "min-width: 108px;"}
    display: flex;
    align-items: center;
    transition: 0.2s all ease-in-out;
    text-transform: uppercase;
    padding: 5px 10px;
    white-space: pre;
    color: #bbc1c6;
    ${({ open }) => open && "background: #1A293C;"}
    ${({ open }) => open && "color: white;"}

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      min-width: 83px;
      font-size: 10px;
    }
  }
  div.apply {
    height: 100%;
    display: flex;
    align-items: center;
    transition: 0.2s all ease-in-out;
    text-transform: uppercase;
    padding: 5px 10px;
    white-space: pre;
    color: white;
    ${({ open }) => open && "background: #FD3237;"}
    ${({ open }) => open && "cursor: pointer;"}

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      font-size: 10px;
    }
  }
  span.icon {
    border-left: 2px solid #bbc1c6;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    ${({ open }) => open && "background: #1A293C;"}
    ${({ open }) => open && "cursor: pointer;"}
  }

  &:hover {
    ${({ open }) => !open && "transform: translateX(3px);"}
  }
`

const InputWrap = styled.form`
  overflow: hidden;
  width: 100%;
  transition: all 0.3s ease-in-out;
`

const Input = styled.input`
  border: none;
  width: 100%;
  padding: 10px;
  &:active,
  &:focus {
    outline: none;
  }
`

export default Coupon
