import React from "react"
import styled from "styled-components"
import { Img } from "react-image"
import EllipsisLoader from "../../zzz/organisms/ellipsisLoader"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import Cross from "../../zzz/atoms/icons/files/cross.jsx"
import { CheckOrCross, Remove } from "./CompareProducts"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const MobileWrapper = ({ items, getPrice, handleRemoveEvent }) => {
  return (
    <Container>
      {items.map(function (pr, index) {
        return (
          <Card key={`${pr.wordpress_id}-${index}`}>
            <Padding>
              <Img
                src={pr.images[0].src}
                loader={<EllipsisLoader height="100px" message="" />}
                width="200px"
                height="200px"
              />
              <h4>{pr.name}</h4>
              <h5>{getPrice(pr)}</h5>
            </Padding>

            <Block>
              <Row>
                <div>Bedroom Type: </div>
                <div>
                  {pr.acf.bedroom_type.map((roomType, index) => (
                    <p key={`${roomType}-${index}`}>{roomType}</p>
                  ))}
                  {/* <CheckOrCross check={true} /> */}
                </div>
              </Row>
              <Row>
                <div>Sizes: </div>
                <div>
                  {pr.attributes.find((at) => at.name == "Size") != null &&
                    pr.attributes
                      .filter((at) => at.name == "Size")[0]
                      .options.map((sizes, index) => (
                        <p key={`${sizes}-${index}`}>{sizes}</p>
                      ))}
                  {/* <CheckOrCross check={false} /> */}
                </div>
              </Row>
              <Row>
                <div>Comfort Level: </div>
                {/* <div>{pr.acf.comfort_level.name}</div> */}
              </Row>
              <Row>
                <div>Weight Capacity: </div>
                <div>{pr.acf.weight_capacity} kg</div>
              </Row>
              <Row>
                <div>Mattresss Type: </div>
                <div>{pr.acf.mattress_type}</div>
              </Row>
              <Row>
                <div>Guarantee: </div>
                <div>
                  {pr.acf.guarantee == "1"
                    ? pr.acf.guarantee + " year"
                    : pr.acf.guarantee + " years"}
                </div>
              </Row>
              <Row>
                <div>Warrantee: </div>
                <div>
                  {pr.acf.warrantee == "1"
                    ? pr.acf.warrantee + " year"
                    : pr.acf.warrantee + " years"}
                </div>
              </Row>
              <Row>
                <div>High Resilience Foam: </div>
                <div>
                  <CheckOrCross check={pr.acf.high_resilience_foam == "Yes"} />
                </div>
              </Row>
              <Row>
                <div>Latex Foam: </div>
                <div>
                  <CheckOrCross check={pr.acf.latex_foam == "Yes"} />
                </div>
              </Row>
              <Row>
                <div>Memory Foam: </div>
                <div>
                  <CheckOrCross check={pr.acf.memory_foam == "Yes"} />
                </div>
              </Row>
              <Row>
                <div>Fabric Type: </div>
                <div>{pr.acf.fabric_type}</div>
              </Row>
              <Row>
                <div>Zero Movement Transfer: </div>
                <div>
                  <CheckOrCross
                    check={pr.acf.zero_movement_transfer == "Yes"}
                  />
                </div>
              </Row>
              <Row>
                <div>Limited Movement Transfer: </div>
                <div>
                  <CheckOrCross
                    check={pr.acf.limited_movement_transfer == "Yes"}
                  />
                </div>
              </Row>
              <Row>
                <div>No Turn Mattress: </div>
                <div>
                  <CheckOrCross
                    check={pr.acf.turn__no_turn_mattress == "No Turn"}
                  />
                </div>
              </Row>
              <Row>
                <div>Mattress in Box: </div>
                <div>
                  <CheckOrCross check={pr.acf.mattress_in_box == "Yes"} />
                </div>
              </Row>
              <Row>
                <div>100 Day Comfort Trial Available: </div>
                <div>
                  <CheckOrCross
                    check={pr.acf.comfort_trial_available == "Yes"}
                  />
                </div>
              </Row>
              <Row>
                <div>100 Day Comfort Trial Available: </div>
                <div>
                  <CheckOrCross
                    check={pr.acf.comfort_trial_available == "Yes"}
                  />
                </div>
              </Row>

              <Remove
                noBorder
                onClick={() => handleRemoveEvent(pr.wordpress_id)}
              >
                <div>REMOVE</div>
                <SvgIcon SvgComponent={Cross} hue="tusk" shade="040" />
              </Remove>
            </Block>
          </Card>
        )
      })}
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Padding = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h5,
  h4 {
    text-align: center;
  }
  h5 {
    font-weight: bold;
  }
`

const Container = styled.div``
const Card = styled.div`
  border-radius: 4px;
  border: 2px solid #cfd5db;
  background: white;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  span.icon {
    &.check {
      background: rgba(0, 200, 81, 0.37);
      border-radius: 200px;
    }
    &.cross {
      border-radius: 200px;
      background: rgba(186, 192, 197, 0.28);
    }
  }
`
const Block = styled.div`
  border-top: 2px solid #cfd5db;
`
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  > div {
    padding: 10px;
  }
  &:nth-child(odd) {
    background: #dee3e8;
  }
`

export default MobileWrapper
