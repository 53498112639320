import React from "react"

const Logo = () => (
  <svg viewBox="0 0 4157.5 1122.5" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M835 232v203h37v79H692v-79h44V211h-44v-79h199l81 250 83-250h195v79h-43v224h43v79h-181v-79h38V235l-101 279h-69z"
      fill="#231f20"
    />
    <path
      d="M835 234v201h38v80H692v-80h43V211h-43v-79h199l81 248 82-248h196v79h-43v224h43v80h-181v-80h38V238l-101 277h-69zm-1 201V230l1 2 102 282h69l101-279 1-2v203h-39v78h180v-78h-43V210h43v-77h-194l-83 249v1-1l-82-249H693v77h43v226h-43v78h179v-78h-38z"
      fill="#231f20"
    />
    <path
      d="M835 232v203h37v79H692v-79h44V211h-44v-79h199l81 250 83-250h195v79h-43v224h43v79h-181v-79h38V235l-101 279h-69z"
      fill="#231f20"
    />
    <path
      d="M835 236v199h38v80H692v-80h43V211h-43v-79h199l81 247 82-247h196v79h-43v224h43v80h-182v-80h39V239l-100 276h-70zm-1 199V232l1-1 103 283h68l101-279h1v201h-38v78h179v-78h-43V210h43v-77h-194l-82 249-1 2-1-2-81-249H693v77h43v226h-43v78h179v-78h-38zM1529 331l-32-87-30 87zm-87 73l-13 31h38v79h-195v-79h44l101-224h-44v-79h168l137 303h43v79h-194v-79h37l-12-31z"
      fill="#231f20"
    />
    <path
      d="M1528 330l-31-85-30 85zm-30-86l31 86v1h-63v-1l31-86v-1zm-56 160l-12 31h37v80h-195v-80h44l100-224h-43v-79h169l136 303h44v80h-195v-80h36l-11-31h-110zm-13 31l13-31h111l11 31v1h-37v78h194v-78h-44l-136-303h-167v77h43v1l-101 225h-43v78h193v-78h-37v-1z"
      fill="#231f20"
    />
    <path
      d="M1529 331l-32-87-30 87zm-87 73l-13 31h38v79h-195v-79h44l101-224h-44v-79h168l137 303h43v79h-194v-79h37l-12-31z"
      fill="#231f20"
    />
    <path
      d="M1528 330l-31-84-29 84zm-30-86l31 86 1 1h-64v-1l31-86v-2zm-56 161l-12 30h37v80h-195v-80h43l101-224h-43v-79h169l136 303h44v80h-196v-80h37l-11-30zm-13 30l12-31 1-1h111v1l11 31 1 1h-37v78h192v-78h-43l-136-303h-167v77h44l-1 1-101 225h-43v78h193v-78h-38zM1939 435h42v79h-193v-79h41V211h-41v52h-81V132h355v131h-81v-52h-42z"
      fill="#231f20"
    />
    <path
      d="M1940 435h42v80h-195v-80h42V211h-41v53h-81V132h355v132h-81v-53h-41zm41 1h-42V210h43v53h79V133h-354v130h80v-53h42v226h-41v78h193z"
      fill="#231f20"
    />
    <path
      d="M1939 435h42v79h-193v-79h41V211h-41v52h-81V132h355v131h-81v-52h-42z"
      fill="#231f20"
    />
    <path
      d="M1940 435h42v80h-195v-80h42V211h-41v53h-82V132h356v132h-81v-53h-41zm41 1h-42V210h43v52h79V133h-353v129h79v-52h43v226h-42v78h193zM2339 435h42v79h-193v-79h41V211h-41v52h-81V132h355v131h-81v-52h-42z"
      fill="#231f20"
    />
    <path
      d="M2340 435h42v80h-195v-80h42V211h-41v53h-81V132h355v132h-81v-53h-41zm41 1h-42V210h43v53h79V133h-353v130h79v-53h43v226h-42v78h193z"
      fill="#231f20"
    />
    <path
      d="M2339 435h42v79h-193v-79h41V211h-41v52h-81V132h355v131h-81v-52h-42z"
      fill="#231f20"
    />
    <path
      d="M2340 435h42v80h-195v-80h42V211h-41v53h-82V132h356v132h-81v-53h-41zm41 1h-42V210h43v52h79V133h-353v129h79v-52h43v226h-42v78h193zM2661 309h22c28 0 49-4 62-11 13-8 19-19 19-35s-5-28-17-34c-11-7-31-10-59-10h-27zm0 126h27v79h-180v-79h43V211h-43v-79h180c38 0 66 2 83 5 16 3 31 9 44 16a123 123 0 0161 106c0 26-7 50-22 71s-35 37-61 48l55 57h42v79h-97l-132-136z"
      fill="#231f20"
    />
    <path
      d="M2662 308h21l37-2c10-2 18-5 25-9 6-3 11-8 14-14 3-5 5-12 5-20s-2-15-4-21c-3-5-7-10-13-13s-13-6-23-8l-36-2h-26v89zm21 1h-22v-91h27c14 0 26 1 36 3 10 1 18 4 23 7 6 4 10 8 13 14s5 13 5 21-2 15-5 21c-4 6-8 10-15 14s-15 7-25 8c-11 2-23 3-37 3zm-21 126h27v80h-181v-80h43V211h-43v-79h180a854 854 0 0183 5 152 152 0 0144 16 127 127 0 0145 45 121 121 0 01-5 132c-8 11-17 20-27 28s-22 14-35 20l55 57h43v80h-98l-131-137v57zm26 1h-27v-59h1l132 137h96v-78h-42l-56-58v-1c14-5 25-12 35-20a129 129 0 0044-61 123 123 0 00-11-97 122 122 0 00-46-45 119 119 0 00-43-16l-34-4-49-1h-180v77h44v226h-44v78h180v-78z"
      fill="#231f20"
    />
    <path
      d="M2661 309h22c28 0 49-4 62-11 13-8 19-19 19-35s-5-28-17-34c-11-7-31-10-59-10h-27zm0 126h27v79h-180v-79h43V211h-43v-79h180c38 0 66 2 83 5 16 3 31 9 44 16a123 123 0 0161 106c0 26-7 50-22 71s-35 37-61 48l55 57h42v79h-97l-132-136z"
      fill="#231f20"
    />
    <path
      d="M2662 308h21c14 0 26-1 37-3 10-1 18-4 24-8 7-3 12-8 15-14 3-5 5-12 5-20s-2-15-5-20c-2-6-7-10-12-14-6-3-13-6-23-7-10-2-22-3-36-3h-26v89zm21 1h-22v-91h27l36 2c10 2 18 5 24 8 5 4 10 8 13 14 2 6 4 13 4 21s-2 15-5 21-8 11-15 14c-6 4-15 7-25 9-11 2-23 2-37 2zm-21 126h27v80h-182v-80h44V211h-44v-79h181a854 854 0 0183 5 153 153 0 0144 16 128 128 0 0146 45 121 121 0 01-6 132c-8 11-17 20-27 28s-21 14-34 20l54 57h43v80h-98l-131-136zm25 1h-26v-60l1 1 132 137h96v-78h-42l-56-58-1-1h1c13-5 25-12 35-20a129 129 0 0043-61 123 123 0 00-11-97 122 122 0 00-45-45 118 118 0 00-44-16 853 853 0 00-82-5h-179v77h43v226h-43v78h178v-78zM3157 436v-44h99v122h-327v-78h43V211h-43v-79h327v123h-99v-44h-75v67h99v86h-99v72z"
      fill="#231f20"
    />
    <path
      d="M3157 436v-45h99v124h-328v-79h44V211h-44v-79h328v123h-99v-44h-74v67h99v86h-99v72zm1-44v44h-76v-73h99v-84h-99v-69h76v45h97V133h-326v77h43v226h-43v78h326V392z"
      fill="#231f20"
    />
    <path
      d="M3157 436v-44h99v122h-327v-78h43V211h-43v-79h327v123h-99v-44h-75v67h99v86h-99v72z"
      fill="#231f20"
    />
    <path
      d="M3157 435v-44h99v124h-328v-80h43V211h-43v-79h328v124h-99v-45h-74v67h99v86h-99v71zm1-42v44h-76v-74h99v-84h-99v-69h76v44h97V133h-326v77h44v227h-44v77h326V392zM3403 501v12h-87V388h102c2 18 7 32 14 40s17 13 31 13c12 0 23-4 31-11a31 31 0 008-42c-4-5-9-10-15-13-8-4-21-9-39-14l-36-12c-32-12-57-27-73-45a90 90 0 01-24-62c0-33 13-61 40-84 27-22 60-33 99-33a124 124 0 0170 20v-11h82v110h-93c-2-13-7-23-15-31-7-7-17-10-28-10s-20 3-28 9c-7 6-11 13-11 22 0 7 2 13 5 18 4 5 8 9 15 12l43 15 45 15c27 12 48 27 62 44 13 17 20 37 20 59 0 34-14 63-42 88a146 146 0 01-138 32c-13-4-25-9-38-16"
      fill="#231f20"
    />
    <path
      d="M3404 501v13h-88V388h102c2 9 3 17 6 24l8 16c4 4 8 7 13 9s11 3 18 3l17-2 14-8c4-3 7-7 9-11a30 30 0 002-23 32 32 0 00-10-15l-8-5a401 401 0 00-61-22 483 483 0 01-57-25c-12-7-22-15-30-24a94 94 0 01-24-63 106 106 0 0140-84c13-11 28-20 45-25a168 168 0 0191-4 118 118 0 0132 15v-10h84v110h-94c-2-7-3-12-6-17-2-5-5-10-9-13-4-4-8-6-13-8-4-2-10-3-15-3l-15 2c-5 2-9 4-12 7-4 3-7 6-9 10s-2 8-2 12l1 9a31 31 0 0018 21 243 243 0 0043 14 585 585 0 0145 16c14 6 26 13 36 20 11 7 19 15 26 24a93 93 0 0120 59c0 17-3 33-10 48a145 145 0 01-151 76 142 142 0 01-38-11l-18-9zm-1 12v-13a193 193 0 0057 20l19 2c19 0 37-4 54-10a144 144 0 0077-68 110 110 0 005-78c-3-10-8-19-15-28-6-8-15-16-25-23a210 210 0 00-55-28 584 584 0 00-52-16l-18-6-8-6a32 32 0 01-10-15l-1-10c0-5 1-9 3-12 1-4 4-7 8-10s8-6 13-7a48 48 0 0131 0l12 8 10 13 5 17h93V135h-82v11-1a131 131 0 00-51-19l-19-1c-20 0-38 3-54 8-17 6-31 14-45 26a105 105 0 00-34 117c5 10 11 19 19 28s18 17 30 25a235 235 0 0056 24 1899 1899 0 0045 15l16 7a41 41 0 0115 13 33 33 0 015 17 31 31 0 01-13 26c-4 3-9 6-14 7a57 57 0 01-35 0c-5-2-10-5-13-10-4-4-7-9-9-16s-4-14-5-24h-102v125h87z"
      fill="#231f20"
    />
    <path
      d="M3403 501v12h-87V388h102c2 18 7 32 14 40s17 13 31 13c12 0 23-4 31-11a31 31 0 008-42c-4-5-9-10-15-13-8-4-21-9-39-14l-36-12c-32-12-57-27-73-45a90 90 0 01-24-62c0-33 13-61 40-84 27-22 60-33 99-33a124 124 0 0170 20v-11h82v110h-93c-2-13-7-23-15-31-7-7-17-10-28-10s-20 3-28 9c-7 6-11 13-11 22 0 7 2 13 5 18 4 5 8 9 15 12l43 15 45 15c27 12 48 27 62 44 13 17 20 37 20 59 0 34-14 63-42 88a146 146 0 01-138 32c-13-4-25-9-38-16"
      fill="#231f20"
    />
    <path
      d="M3404 502v12h-89V387h104v1l5 24c2 6 5 12 9 16 3 4 7 7 12 9s11 3 18 3c6 0 12-1 17-3 5-1 10-4 14-7s7-7 9-11a30 30 0 002-23 32 32 0 00-10-15l-8-5-16-7a401 401 0 00-45-15 483 483 0 01-57-25c-12-7-22-15-30-24a94 94 0 01-24-63 106 106 0 0140-84c13-11 28-20 44-26a168 168 0 0192-3 118 118 0 0132 15v-10h84v110h-95c-1-7-3-12-5-17l-9-13-13-8c-5-2-10-2-15-2-6 0-11 0-15 2-5 1-9 3-12 6-4 3-7 7-8 10l-3 12 1 9a31 31 0 0018 21 243 243 0 0043 14 587 587 0 0145 15c14 7 26 13 36 21 11 7 19 15 26 24a94 94 0 0121 59c0 17-4 33-11 48a145 145 0 01-151 77 143 143 0 01-38-11l-18-9zm-2 11v-13h1a193 193 0 0057 20l19 1a144 144 0 00131-77 110 110 0 005-78c-3-10-8-19-15-27-7-9-15-17-25-24a209 209 0 00-55-27 584 584 0 00-52-17l-18-6-8-5a32 32 0 01-10-16l-1-10c0-5 0-9 2-12 2-4 5-8 9-11l12-7a49 49 0 0132 1c4 2 9 4 13 8 3 4 7 8 9 13 3 5 4 11 6 17h92V135h-82v11h-1a131 131 0 00-50-20l-19-1c-20 0-38 3-54 9-16 5-31 14-45 25a105 105 0 00-34 116c5 10 11 20 19 29s18 17 30 24a235 235 0 0056 25 1899 1899 0 0045 15l17 7a41 41 0 0114 13 33 33 0 016 17 31 31 0 01-13 26l-15 8a57 57 0 01-35-1c-5-2-10-5-13-9-4-5-7-10-9-17-3-6-4-14-6-23h-100v124h85zM3759 501v12h-87V388h102c2 18 7 32 14 40s17 13 31 13c12 0 23-4 31-11a31 31 0 008-42c-4-5-9-10-15-13-8-4-21-9-38-14l-37-12c-32-12-56-27-73-45a90 90 0 01-24-62c0-33 14-61 40-84 27-22 60-33 99-33a124 124 0 0170 20v-11h82v110h-93c-2-13-7-23-15-31-7-7-17-10-28-10s-20 3-28 9c-7 6-11 13-11 22 0 7 2 13 5 18 4 5 9 9 15 12l43 15 45 15c28 12 48 27 62 44 13 17 20 37 20 59 0 34-14 63-42 88a146 146 0 01-138 32c-12-4-25-9-38-16"
      fill="#231f20"
    />
    <path
      d="M3760 501v13h-88V388h103c1 9 2 17 5 24l8 16c4 4 8 7 13 9s11 3 18 3l17-2 14-8 10-11a30 30 0 001-23l-3-8a35 35 0 00-15-12 400 400 0 00-61-22 483 483 0 01-56-25c-13-7-23-15-31-24a94 94 0 01-24-63 106 106 0 0140-84c13-11 28-20 45-25a168 168 0 0191-4 118 118 0 0133 15v-10h83v110h-94c-1-7-3-12-6-17-2-5-5-10-9-13-4-4-8-6-13-8-4-2-9-3-15-3l-15 2c-5 2-9 4-12 7-4 3-7 6-9 10l-2 12 1 9a31 31 0 0018 21 243 243 0 0044 14 587 587 0 0144 16c14 6 26 13 36 20 11 7 19 15 26 24a93 93 0 0120 59c0 17-3 33-10 48a145 145 0 01-151 76 143 143 0 01-38-11l-18-9zm-1 12v-13a193 193 0 0057 20l19 2c20 0 38-4 54-10a144 144 0 0077-68 110 110 0 006-78c-4-10-9-19-16-28-6-8-15-16-25-23a209 209 0 00-54-28 585 585 0 00-53-16l-17-6-9-6a32 32 0 01-10-15l-1-10c0-5 1-9 3-12 2-4 4-7 8-10s8-6 13-7a48 48 0 0131 0l13 8 9 13 6 17h92V135h-82v11-1a131 131 0 00-51-19l-19-1c-20 0-38 3-54 8-16 6-31 14-45 26a105 105 0 00-34 117c5 10 11 19 19 28s18 17 30 25a235 235 0 0057 24 1886 1886 0 0044 15l17 7a40 40 0 0114 13 33 33 0 015 17 31 31 0 01-12 26c-5 3-10 6-15 7a57 57 0 01-35 0c-5-2-10-5-13-10-4-4-7-9-9-16s-4-14-5-24h-102v125h87z"
      fill="#231f20"
    />
    <path
      d="M3759 501v12h-87V388h102c2 18 7 32 14 40s17 13 31 13c12 0 23-4 31-11a31 31 0 008-42c-4-5-9-10-15-13-8-4-21-9-38-14l-37-12c-32-12-56-27-73-45a90 90 0 01-24-62c0-33 14-61 40-84 27-22 60-33 99-33a124 124 0 0170 20v-11h82v110h-93c-2-13-7-23-15-31-7-7-17-10-28-10s-20 3-28 9c-7 6-11 13-11 22 0 7 2 13 5 18 4 5 9 9 15 12l43 15 45 15c28 12 48 27 62 44 13 17 20 37 20 59 0 34-14 63-42 88a146 146 0 01-138 32c-12-4-25-9-38-16"
      fill="#231f20"
    />
    <path
      d="M3760 502v12h-89V387h104v1l5 24c2 6 5 12 9 16 3 4 7 7 12 9s11 3 18 3c6 0 12-1 17-3 5-1 10-4 14-7s7-7 9-11a30 30 0 002-23l-4-8a34 34 0 00-14-12l-16-7a399 399 0 00-45-15 483 483 0 01-57-25c-12-7-22-15-30-24a94 94 0 01-24-63 106 106 0 0140-84c13-11 28-20 45-26a168 168 0 0191-3 118 118 0 0132 15v-10h84v110h-94l-6-17c-2-5-6-10-9-13l-13-8c-4-2-10-2-15-2-6 0-11 0-15 2-5 1-9 3-12 6-4 3-7 7-8 10l-3 12 1 9a31 31 0 0018 21 242 242 0 0044 14 587 587 0 0144 15c14 7 26 13 36 21 11 7 19 15 26 24a94 94 0 0121 59c0 17-4 33-11 48a145 145 0 01-151 77 143 143 0 01-38-11l-18-9zm-1 11v-13h1a193 193 0 0056 20l19 1a144 144 0 00131-77 110 110 0 005-78c-3-10-8-19-15-27-7-9-15-17-25-24a209 209 0 00-55-27 585 585 0 00-52-17l-18-6-8-5a32 32 0 01-10-16l-1-10 2-12c2-4 5-8 9-11l13-7a49 49 0 0131 1c5 2 9 4 13 8s7 8 9 13c3 5 4 11 6 17h92V135h-81v11h-1a131 131 0 00-51-20l-19-1c-20 0-38 3-54 9-16 5-31 14-44 25-14 11-24 24-30 37a105 105 0 00-4 79c4 10 10 20 18 29s18 17 30 24a235 235 0 0057 25 1881 1881 0 0045 15l16 7a41 41 0 0114 13 33 33 0 016 17 31 31 0 01-13 26c-5 3-9 6-15 8a57 57 0 01-35-1c-5-2-10-5-13-9-4-5-7-10-9-17-3-6-4-14-5-23h-101v124h86zM597 1001h-91l-86-303h-31v-79h162v79h-33l40 156 58-235h87l57 240 40-161h-34v-79h163v79h-31l-86 303h-91l-62-237z"
      fill="#231f20"
    />
    <path
      d="M597 1002h-92l-86-304h-31v-79h164v79h-34l40 154 57-233h88l57 239 40-160h-34v-79h163v79h-31l-86 304h-91l-62-237-62 237zm-91-1h90l63-237v-2 2l62 237h90l87-303v-1h31v-77H767v77h34v1l-40 162-1 1v-1l-57-240h-87l-58 234v1l-1-1-40-156v-1h34v-77H389v77h31v1z"
      fill="#231f20"
    />
    <path
      d="M597 1001h-91l-86-303h-31v-79h162v79h-33l40 156 58-235h87l57 240 40-161h-34v-79h163v79h-31l-86 303h-91l-62-237z"
      fill="#231f20"
    />
    <path
      d="M597 1002h-92l-86-304h-31v-79h164v79h-33l39 153 57-232h88l57 238 39-159h-33v-79h164v79h-31l-87 304h-92l-61-236-62 236zm-91-1h90l62-237 1-3 1 3 62 237h89l86-303 1-1h30v-77H767v77h34v1l-40 162h-1l-58-240h-86l-58 234h-1l-40-156v-1h34v-77H389v77h31v1l86 303zM1145 818l-28-87-28 87zm-78 73l-11 31h33v79H915v-79h39l91-224h-39v-79h151l122 303h39v79h-174v-79h33l-11-31z"
      fill="#231f20"
    />
    <path
      d="M1145 817l-28-85-27 85zm-27-86l28 86v1h-57v-1l28-86v-1zm-50 161l-12 30h34v80H914v-80h40l90-224h-39v-79h152l123 303h39v80h-176v-80h33l-10-30zm-12 30l11-31h100l10 31v1h-33v78h174v-78h-39l-123-303h-150v77h39v1l-91 225h-39v78h174v-78h-34z"
      fill="#231f20"
    />
    <path
      d="M1145 818l-28-87-28 87zm-78 73l-11 31h33v79H915v-79h39l91-224h-39v-79h151l122 303h39v79h-174v-79h33l-11-31z"
      fill="#231f20"
    />
    <path
      d="M1144 817l-27-84-27 84zm-26-86l28 86v1h-57v-1l28-86v-2zm-50 161l-11 30h33v80H914v-80h39l91-224h-39v-79h152l123 303h39v80h-176v-80h33l-10-30zm-13 30l12-31v-1h100v1l10 31 1 1h-34v78h174v-78h-39l-123-303h-150v77h40l-1 1-91 225h-39v78h174v-78h-34zM1475 796h20c25 0 44-4 56-11 11-8 17-19 17-35s-5-28-15-34c-10-7-28-10-54-10h-24zm0 126h24v79h-162v-79h39V698h-39v-79h162c35 0 60 2 75 5a118 118 0 0181 61c9 19 14 39 14 61a128 128 0 01-76 119l51 57h38v79h-88l-119-136z"
      fill="#231f20"
    />
    <path
      d="M1476 795h19l33-2c9-2 17-5 22-9 6-3 10-8 13-14 3-5 5-12 5-20s-2-15-4-21c-3-5-6-10-11-13s-12-6-21-8l-33-2h-23zm19 1h-20v-91h24c13 0 24 1 33 3 9 1 16 4 21 7 5 4 9 8 12 14 2 6 3 13 3 21s-1 15-4 21-7 10-13 14-14 7-23 8c-9 2-20 3-33 3zm-19 126h24v80h-163v-80h39V698h-39v-79h162a693 693 0 0175 5 127 127 0 0140 16 117 117 0 0141 45c5 10 8 19 11 30s4 20 4 31a136 136 0 01-21 71 125 125 0 01-55 48l50 57h38v80h-88l-118-136v56zm23 1h-24v-59h1l119 137h86v-78h-38l-50-59a134 134 0 0056-47 124 124 0 0020-71 132 132 0 00-15-60 122 122 0 00-41-45 103 103 0 00-39-16l-30-4-45-1h-161v77h39v226h-39v78h161v-78z"
      fill="#231f20"
    />
    <path
      d="M1475 796h20c25 0 44-4 56-11 11-8 17-19 17-35s-5-28-15-34c-10-7-28-10-54-10h-24zm0 126h24v79h-162v-79h39V698h-39v-79h162c35 0 60 2 75 5a118 118 0 0181 61c9 19 14 39 14 61a128 128 0 01-76 119l51 57h38v79h-88l-119-136z"
      fill="#231f20"
    />
    <path
      d="M1476 795h19c12 0 23-1 33-3 9-1 16-4 22-8 6-3 10-8 13-14 3-5 4-12 4-20s-1-15-3-20c-3-6-7-10-12-14-5-3-12-6-20-7-9-2-20-3-33-3h-23zm19 1h-20v-91h24l33 2c9 2 16 5 21 8 5 4 9 8 12 14 2 6 4 13 4 21s-2 15-5 21-7 11-13 14c-6 4-14 7-23 9s-20 2-33 2zm-19 126h24v80h-163v-80h39V698h-39v-79h162a693 693 0 0175 5 127 127 0 0140 16 118 118 0 0141 45 129 129 0 0115 61 137 137 0 01-20 71 125 125 0 01-55 48l49 57h38v80h-88l-118-136zm23 1h-24v-60l1 1 119 137h86v-78h-38l-50-58-1-1h1a134 134 0 0055-47 124 124 0 0020-71 131 131 0 00-14-60 121 121 0 00-41-45 102 102 0 00-39-16l-30-4-45-1h-161v77h39v226h-39v78h161v-78zM1922 923v-44h88v122h-294v-78h39V698h-39v-79h294v123h-88v-44h-68v67h89v86h-89v72z"
      fill="#231f20"
    />
    <path
      d="M1921 923v-45h90v124h-295v-79h39V698h-39v-79h295v123h-90v-44h-66v67h89v86h-89v72zm1-44v45h-68v-74h89v-84h-89v-69h68v45h88V620h-293v77h38v227h-38v77h293V879z"
      fill="#231f20"
    />
    <path
      d="M1922 923v-44h88v122h-294v-78h39V698h-39v-79h294v123h-88v-44h-68v67h89v86h-89v72z"
      fill="#231f20"
    />
    <path
      d="M1921 922v-44h90v124h-295v-80h38V698h-38v-79h295v124h-90v-45h-66v67h89v86h-89v71zm1-42v44h-68v-74h89v-84h-89v-69h68v44h88V620h-293v77h39v227h-39v77h293V880zM2100 922V698h-38v-79h174v79h-36v69h118v-69h-37v-79h175v79h-39v224h39v79h-175v-79h37v-76h-118v76h37v79h-175v-79z"
      fill="#231f20"
    />
    <path
      d="M2100 922V698h-39v-79h176v79h-37v69h118v-69h-37v-79h176v79h-40v224h40v80h-176v-80h37v-76h-118v76h37v80h-176v-80h1zm1-224v225h-39v78h174v-78h-37v-78h120v78h-38v78h175v-78h-39V697h39v-77h-175v77h38v71h-120v-71h37v-77h-174v77h39z"
      fill="#231f20"
    />
    <path
      d="M2100 922V698h-38v-79h174v79h-36v69h118v-69h-37v-79h175v79h-39v224h39v79h-175v-79h37v-76h-118v76h37v79h-175v-79z"
      fill="#231f20"
    />
    <path
      d="M2100 922V698h-39v-79h176v79h-37v69h118v-69h-38v-79h177v79h-39v224h39v80h-177v-80h38v-76h-118v76h37v80h-176v-80h1zm1-224v225h-39v78h174v-78h-37v-78h120v78h-37v78h174v-78h-40V697h40v-77h-174v77h37v71h-120v-71h37v-77h-174v77h39zM2595 807c0 30 11 55 32 75 20 21 45 32 74 32s54-11 75-31c20-20 30-45 30-76a102 102 0 00-104-100c-29 0-55 10-75 29a94 94 0 00-32 71zm-101-1a183 183 0 0161-138 204 204 0 01147-56 210 210 0 01143 56 183 183 0 0162 139 200 200 0 01-58 142 205 205 0 01-148 60 198 198 0 01-150-61 196 196 0 01-57-142"
      fill="#231f20"
    />
    <path
      d="M2596 807a104 104 0 0031 75 101 101 0 0074 31c15 0 28-2 41-7 12-5 23-13 33-23a101 101 0 0031-76 97 97 0 00-31-70 103 103 0 00-73-30c-14 0-28 3-41 8a105 105 0 00-57 54c-6 11-8 24-8 38zm7 41a105 105 0 010-80 106 106 0 01100-62 104 104 0 0196 62 111 111 0 010 81c-5 13-13 24-23 34a103 103 0 01-75 31 102 102 0 01-75-31c-10-11-18-22-23-35zm-110-42a189 189 0 0162-139 209 209 0 01187-52 204 204 0 01166 192 210 210 0 01-15 77 189 189 0 01-44 65 206 206 0 01-148 60 236 236 0 01-83-15 192 192 0 01-67-46 209 209 0 01-43-64 191 191 0 01-15-78zm5-38a188 188 0 000 79 191 191 0 0085 127 191 191 0 0075 31 236 236 0 00123-12 206 206 0 0068-45 200 200 0 0054-101 209 209 0 000-78 184 184 0 00-58-101 207 207 0 00-185-52 209 209 0 00-131 82 189 189 0 00-31 70"
      fill="#231f20"
    />
    <path
      d="M2595 807c0 30 11 55 32 75 20 21 45 32 74 32s54-11 75-31c20-20 30-45 30-76a102 102 0 00-104-100c-29 0-55 10-75 29a94 94 0 00-32 71zm-101-1a183 183 0 0161-138 204 204 0 01147-56 210 210 0 01143 56 183 183 0 0162 139 200 200 0 01-58 142 205 205 0 01-148 60 198 198 0 01-150-61 196 196 0 01-57-142"
      fill="#231f20"
    />
    <path
      d="M2596 807a104 104 0 0031 75 101 101 0 0074 31 101 101 0 0074-30 101 101 0 0030-76 97 97 0 00-30-70 103 103 0 00-73-29 105 105 0 00-99 61c-4 11-7 24-7 38zm7 41a105 105 0 010-80 106 106 0 01100-62 104 104 0 0197 62 112 112 0 010 81c-6 13-14 24-24 35a103 103 0 01-75 30 102 102 0 01-75-31c-10-11-18-22-23-35zm-110-42a189 189 0 0162-139 210 210 0 01187-52 204 204 0 01166 192 210 210 0 01-15 78 189 189 0 01-43 64 207 207 0 01-149 61 237 237 0 01-83-16 192 192 0 01-68-45 209 209 0 01-43-65 192 192 0 01-14-78zm5-38a188 188 0 000 79 190 190 0 0053 101 208 208 0 0068 45 201 201 0 0082 15 216 216 0 00116-34c11-8 22-16 32-26a200 200 0 0054-101 209 209 0 000-78 184 184 0 00-58-100 207 207 0 00-185-53 208 208 0 00-131 82 189 189 0 00-31 70M3180 698h-27v-79h158v79h-33v161c0 48-13 85-39 111s-63 39-111 39c-27 0-52-5-73-15s-38-24-52-43c-7-11-13-23-16-37-4-14-5-32-5-55V698h-33v-79h158v79h-26v158c0 22 4 39 11 49 8 10 20 16 36 16 18 0 31-6 39-16 8-11 13-27 13-49z"
      fill="#231f20"
    />
    <path
      d="M3179 698h-27v-79h159v79h-32v161c0 24-4 46-10 64a123 123 0 01-77 77 195 195 0 01-137-6 131 131 0 01-52-43 91 91 0 01-17-37c-1-7-3-15-3-24l-2-31V698h-32v-79h158v79h-26v158c0 11 1 21 3 29s5 15 9 20 8 9 14 11c6 3 13 4 21 4 9 0 16-1 23-4 6-2 11-6 16-11 4-6 7-12 9-21 2-8 3-17 3-28V698zm-26-1h27v159c0 11-1 20-3 29-2 8-6 15-10 20s-9 9-16 12c-6 3-14 4-23 4-8 0-15-1-21-4-6-2-11-6-15-12-4-5-7-12-9-20s-3-18-3-29V697h26v-77h-157v77h33v162a344 344 0 005 55 106 106 0 0017 36 128 128 0 0051 44 193 193 0 0073 15c24 0 45-4 64-10 18-7 34-16 47-29s23-29 29-47c7-18 10-40 10-64V697h33v-77h-158v77z"
      fill="#231f20"
    />
    <path
      d="M3180 698h-27v-79h158v79h-33v161c0 48-13 85-39 111s-63 39-111 39c-27 0-52-5-73-15s-38-24-52-43c-7-11-13-23-16-37-4-14-5-32-5-55V698h-33v-79h158v79h-26v158c0 22 4 39 11 49 8 10 20 16 36 16 18 0 31-6 39-16 8-11 13-27 13-49z"
      fill="#231f20"
    />
    <path
      d="M3179 698h-27v-79h160v79h-33v161c0 24-3 46-10 64a123 123 0 01-77 77 196 196 0 01-137-5 131 131 0 01-52-44 91 91 0 01-17-37l-4-24-1-31V698h-33v-79h159v79h-26v158c0 11 1 20 3 29 2 8 5 14 9 20 4 5 9 9 14 11 6 3 13 4 21 4 9 0 16-1 23-4 6-2 11-6 15-12 5-5 8-12 10-20s3-17 3-28V698zm-26-1h27v159c0 11-1 20-3 29-2 8-5 15-10 20-4 6-9 10-16 12-6 3-14 4-23 4-8 0-15-1-21-4-6-2-11-6-15-12-4-5-7-12-9-20s-3-18-3-29V697h26v-77h-156v77h32v162a343 343 0 005 55 106 106 0 0017 36 128 128 0 0051 43c11 5 22 9 34 12 12 2 25 3 39 3 24 0 45-3 64-9 18-7 34-16 47-29s22-29 29-47c6-18 10-40 10-64V697h32v-77h-157v77zM3438 988v12h-78V875h92c2 18 6 32 12 40 7 8 16 13 28 13 11 0 20-4 28-11 8-6 11-15 11-25 0-6-1-12-4-17s-8-10-13-13c-8-4-19-9-35-14l-33-12c-29-12-51-27-65-45a96 96 0 01-22-62c0-33 12-61 36-84a125 125 0 01122-28c11 3 20 8 30 15v-11h74v110h-84c-2-13-7-23-13-30-7-8-16-11-26-11s-18 3-24 9c-7 6-10 13-10 22 0 7 1 13 4 18s7 9 13 12l39 15 40 15c25 13 44 27 56 44s18 37 18 59c0 34-13 63-38 88a124 124 0 01-158 16"
      fill="#231f20"
    />
    <path
      d="M3439 988v13h-80V875h93l5 24c2 6 4 12 8 16 3 4 7 7 11 9a45 45 0 0031 1c5-2 9-4 13-8 4-3 6-7 8-11a33 33 0 002-23 34 34 0 00-16-20 336 336 0 00-55-22l-13-5c-14-6-27-12-38-20-11-7-20-15-27-24a96 96 0 01-22-63 113 113 0 0136-84c12-11 25-20 40-25a137 137 0 0182-4 100 100 0 0130 15v-10h74v110h-84c-1-7-3-12-5-17s-5-10-9-13a33 33 0 00-25-11c-5 0-9 1-13 3a33 33 0 00-19 16l-2 12 1 9 3 9a30 30 0 0013 12l16 6a599 599 0 0047 17l17 7c12 6 23 13 32 20 9 8 17 15 23 24a97 97 0 0118 59 121 121 0 01-37 88 127 127 0 01-158 16zm-1 12v-13a167 167 0 0034 16 116 116 0 0034 6 126 126 0 00128-125 103 103 0 00-19-59c-6-8-13-16-22-23a184 184 0 00-49-28 487 487 0 00-47-16l-16-6a32 32 0 01-13-13l-4-8-1-10a29 29 0 0110-22c4-3 7-6 12-7a39 39 0 0127 0c5 2 9 5 12 8l8 13c3 5 4 11 5 17h84V622h-74v11-1a115 115 0 00-30-15 101 101 0 00-33-5 121 121 0 00-116 71 115 115 0 00-3 79c3 11 9 20 16 29s16 17 27 25a203 203 0 0051 24 1516 1516 0 0041 15l14 7a36 36 0 0113 13 34 34 0 012 31c-2 5-5 8-9 12-4 3-8 6-13 7a46 46 0 01-31 0c-5-2-9-5-12-10-3-4-6-9-8-16s-4-14-5-24h-91v125h78z"
      fill="#231f20"
    />
    <path
      d="M3438 988v12h-78V875h92c2 18 6 32 12 40 7 8 16 13 28 13 11 0 20-4 28-11 8-6 11-15 11-25 0-6-1-12-4-17s-8-10-13-13c-8-4-19-9-35-14l-33-12c-29-12-51-27-65-45a96 96 0 01-22-62c0-33 12-61 36-84a125 125 0 01122-28c11 3 20 8 30 15v-11h74v110h-84c-2-13-7-23-13-30-7-8-16-11-26-11s-18 3-24 9c-7 6-10 13-10 22 0 7 1 13 4 18s7 9 13 12l39 15 40 15c25 13 44 27 56 44s18 37 18 59c0 34-13 63-38 88a124 124 0 01-158 16"
      fill="#231f20"
    />
    <path
      d="M3439 989v12h-80V874h93v1l5 24 8 16c3 4 7 7 11 9a45 45 0 0031 1l13-8 8-11a33 33 0 002-23 33 33 0 00-17-20l-14-7a336 336 0 00-40-15l-13-5c-15-5-27-12-38-20-11-7-20-15-28-24a96 96 0 01-22-63 113 113 0 0136-84c13-11 26-20 41-26a138 138 0 0182-3 100 100 0 0129 15v-10h76v110h-85l-5-17c-3-5-5-10-9-13a33 33 0 00-25-10c-5 0-9 0-13 2a33 33 0 00-18 16l-3 12 1 9 4 9a30 30 0 0012 12l16 6a599 599 0 0047 16l17 7 32 21c9 7 17 15 23 24a97 97 0 0119 59 121 121 0 01-38 88 127 127 0 01-158 17zm-1 11v-13h1a167 167 0 0033 16 115 115 0 0034 5 126 126 0 00127-124 102 102 0 00-18-58c-6-9-14-17-23-24a183 183 0 00-48-27 486 486 0 00-47-17l-16-6a32 32 0 01-13-13l-4-8-1-10a29 29 0 0110-23c3-3 7-5 11-6a40 40 0 0129 0c4 2 8 4 11 8 4 4 6 8 9 13l5 17h82V622h-72v11h-1a114 114 0 00-30-16 100 100 0 00-33-5c-18 0-34 3-49 9a120 120 0 00-66 62 114 114 0 00-4 79c4 11 9 20 16 29 8 9 17 17 27 24a203 203 0 0051 25 1513 1513 0 0041 15l14 7a36 36 0 0113 13 35 35 0 012 31c-2 5-5 9-9 12l-13 8a46 46 0 01-31-1c-5-2-9-5-12-9-3-5-6-10-8-17l-5-23h-91v124h78zM3883 923v-44h88v122h-294v-78h39V698h-39v-79h294v123h-88v-44h-68v67h89v86h-89v72z"
      fill="#231f20"
    />
    <path
      d="M3882 923v-45h90v124h-295v-79h39V698h-39v-79h295v123h-90v-44h-67v67h90v86h-90v72zm1-44v45h-68v-74h89v-84h-89v-69h68v45h88V620h-294v77h39v227h-39v77h294V879z"
      fill="#231f20"
    />
    <path
      d="M3883 923v-44h88v122h-294v-78h39V698h-39v-79h294v123h-88v-44h-68v67h89v86h-89v72z"
      fill="#231f20"
    />
    <path
      d="M3882 922v-44h90v124h-296v-80h39V698h-39v-79h296v124h-90v-45h-66v67h89v86h-89v71zm1-42v44h-69v-74h90v-84h-90v-69h69v44h87V620h-292v77h39v227h-39v77h292V880zM4001 584H377v-36h3624z"
      fill="#231f20"
    />
    <path d="M4001 584H376v-36h3625v36zm-3624-1h3624v-35H377z" fill="#231f20" />
    <path d="M4001 584H377v-36h3624z" fill="#231f20" />
    <path d="M4001 585H376v-38h3626v38zm-3624-2h3623v-34H377z" fill="#231f20" />
    <path
      d="M824 220v204h38v79H682v-79h43V199h-43v-78h198l81 249 83-249h195v78h-43v225h43v79h-181v-79h39V224L996 503h-69z"
      fill="#fff"
    />
    <path
      d="M825 224v199h38v81H681v-81h43V200h-43v-80h200v1l80 247 82-247v-1h197v80h-43v223h43v81h-182v-81h38V228L996 503v1h-70v-1zm-2 200V220h2l102 282h68l101-278h2v201h-39v77h180v-77h-44V198h44v-76h-195l-82 248-1 2v-2l-81-248H682v76h44v227h-44v77h179v-77h-38z"
      fill="#231f20"
    />
    <path
      d="M1518 319l-31-87-31 87zm-87 74l-12 31h37v79h-194v-79h43l101-225h-43v-78h168l136 303h44v79h-195v-79h37l-11-31z"
      fill="#fff"
    />
    <path
      d="M1517 318l-30-84-30 84zm-30-86l32 87v1h-64v-1l31-87 1-2zm-55 161l-12 30h37v81h-196v-81h44l100-223h-43v-80h169v1l137 302h43v80h-195v-80h36l-11-30zm-14 31l13-32h111l12 32v1h-37v77h193v-77h-43v-1l-137-302h-167v76h44v1l-101 225v1h-43v77h192v-77h-37z"
      fill="#231f20"
    />
    <path
      d="M1929 424h42v79h-194v-79h42V199h-42v53h-81V121h355v131h-80v-53h-42z"
      fill="#fff"
    />
    <path
      d="M1929 423h42v81h-195v-81h42V200h-40v52h-82V120h356v132h-82v-52h-41zm41 2h-42V198h43v53h79V122h-353v129h79v-53h43v227h-41v77h192z"
      fill="#231f20"
    />
    <path
      d="M2329 424h42v79h-194v-79h42V199h-42v53h-81V121h355v131h-80v-53h-42z"
      fill="#fff"
    />
    <path
      d="M2329 423h42v81h-195v-81h42V200h-40v52h-82V120h356v132h-82v-52h-41zm41 2h-42V198h43v53h79V122h-353v129h79v-53h43v227h-41v77h192z"
      fill="#231f20"
    />
    <path
      d="M2651 297h21c29 0 49-3 62-11 13-7 20-19 20-34 0-17-6-28-17-35-12-7-31-10-60-10h-26zm0 127h26v79h-180v-79h44V199h-44v-78h180c39 0 66 1 83 5 17 3 31 8 44 16a123 123 0 0162 106c0 26-8 49-22 70-15 21-36 37-62 48l56 58h42v79h-97l-132-137z"
      fill="#fff"
    />
    <path
      d="M2651 297h21c14 0 27-1 37-3s18-5 25-8c6-4 11-9 14-14a46 46 0 001-41c-3-6-7-10-13-13-5-4-13-6-23-8s-22-2-36-2h-26zm21 1h-22v-92h27c15 0 27 1 36 3 10 2 18 4 24 8 6 3 10 8 13 13 3 6 4 13 4 22 0 8-1 14-5 20-3 6-8 11-15 15-6 3-15 6-25 8s-23 3-37 3zm-21 125h27v81h-181v-81h43V200h-43v-80h180a854 854 0 0183 5 153 153 0 0144 16 128 128 0 0146 46 121 121 0 01-6 132 152 152 0 01-61 47l55 57h42v81h-98v-1l-131-135zm26 2h-27v-61l1 2 132 136h96v-77h-42v-1l-56-58 1-1c13-5 24-12 35-20a129 129 0 0043-60 123 123 0 00-11-98 122 122 0 00-45-45 118 118 0 00-44-16l-33-3-50-1h-179v76h43v227h-43v77h179v-77z"
      fill="#231f20"
    />
    <path
      d="M3147 425v-45h98v123h-327v-78h43V199h-43v-78h327v122h-98v-44h-75v68h99v85h-99v73z"
      fill="#fff"
    />
    <path
      d="M3146 424v-44h100v124h-329v-80h44V200h-44v-80h329v124h-100v-44h-74v66h99v87h-99v71zm1-43v44h-76v-74h99v-83h-99v-70h76v45h97V122h-325v76h43v227h-43v77h325V381z"
      fill="#231f20"
    />
    <path
      d="M3393 489v13h-88V377h103c2 18 6 31 14 40 7 8 17 12 30 12s23-3 32-10a31 31 0 007-43c-3-5-8-9-14-12-9-5-22-10-39-15l-36-12c-33-12-57-26-73-44a90 90 0 01-24-63c0-33 13-61 40-83 26-23 59-34 98-34a124 124 0 0170 20v-10h83v109h-93c-3-13-8-23-15-30-8-7-17-11-29-11-11 0-20 3-27 9-8 6-11 14-11 22 0 7 1 13 5 18 3 6 8 10 14 13l44 14 44 15c28 13 48 28 62 45s20 36 20 58c0 34-14 64-42 88a146 146 0 01-138 32c-12-3-25-9-37-16"
      fill="#fff"
    />
    <path
      d="M3393 490v12h-88V376h103c1 10 3 17 5 24l9 16c3 4 8 7 13 9s11 3 17 3l17-2c5-2 10-4 14-8 4-3 8-7 10-11a30 30 0 001-22c0-3-2-6-3-8a34 34 0 00-15-13l-16-7a402 402 0 00-44-14 483 483 0 01-57-25c-12-8-23-16-31-25a94 94 0 01-24-63 106 106 0 0140-84c13-11 28-20 45-25a168 168 0 0191-4 118 118 0 0133 15v-10h83v111h-94v-1c-1-6-3-12-6-17-2-5-5-9-9-13-4-3-8-6-12-7a47 47 0 00-30 0 30 30 0 00-20 16c-3 3-4 7-4 11l2 10a31 31 0 0017 20 243 243 0 0044 15 585 585 0 0145 15c13 6 26 13 36 20 10 8 19 16 26 24a94 94 0 0120 59c0 17-4 33-11 48s-17 28-31 41a145 145 0 01-120 36 143 143 0 01-38-11l-18-9zm-1 11v-13l1 1a192 192 0 0056 19l20 2c19 0 37-3 53-10a144 144 0 0077-67 110 110 0 006-79c-4-9-9-18-16-27-6-8-15-16-25-24a209 209 0 00-54-27 583 583 0 00-52-16l-18-7-9-5a32 32 0 01-10-16l-1-10 3-12 8-10c4-3 8-6 13-7a49 49 0 0131 0l13 8 10 13 5 18h92V124h-81v11l-1-1a131 131 0 00-51-19l-19-1c-19 0-37 2-54 8-16 6-31 14-44 25s-23 24-30 38a105 105 0 00-4 79c4 10 10 20 18 28 8 9 18 17 30 25a235 235 0 0057 24 1877 1877 0 0045 15l16 7a41 41 0 0115 13 33 33 0 015 18 31 31 0 01-13 25c-4 4-9 6-14 8a57 57 0 01-36-1c-5-2-9-5-13-9s-7-10-9-17l-5-23h-101v124h86z"
      fill="#231f20"
    />
    <path
      d="M3749 489v13h-88V377h103c2 18 6 31 14 40 7 8 17 12 30 12s23-3 32-10a31 31 0 007-43c-3-5-8-9-14-12-9-5-22-10-39-15l-36-12c-33-12-57-26-73-44a90 90 0 01-24-63c0-33 13-61 40-83 26-23 59-34 98-34a124 124 0 0170 20v-10h83v109h-93c-3-13-8-23-15-30-8-7-17-11-29-11-11 0-20 3-27 9-8 6-11 14-11 22 0 7 1 13 5 18 3 6 8 10 14 13l44 14 44 15c28 13 48 28 62 45s20 36 20 58c0 34-14 64-42 88a146 146 0 01-138 32c-12-3-25-9-37-16"
      fill="#fff"
    />
    <path
      d="M3749 490v12h-88V376h103c1 10 3 17 5 24l9 16c3 4 8 7 13 9s11 3 17 3l17-2c5-2 10-4 14-8 4-3 8-7 10-11a30 30 0 001-22 32 32 0 00-10-15l-8-6-16-7a400 400 0 00-44-14 483 483 0 01-57-25c-12-8-23-16-31-25a94 94 0 01-24-63 106 106 0 0140-84c13-11 28-20 45-25a168 168 0 0191-4 118 118 0 0133 15v-10h83v111h-94v-1c-1-6-3-12-6-17-2-5-5-9-9-13-4-3-8-6-12-7a47 47 0 00-30 0 30 30 0 00-20 16c-3 3-4 7-4 11l2 10a31 31 0 009 15l8 5a244 244 0 0044 15 586 586 0 0145 15c13 6 26 13 36 20 10 8 19 16 26 24a94 94 0 0120 59c0 17-4 33-11 48s-17 28-31 41a145 145 0 01-120 36 143 143 0 01-38-11l-18-9zm-1 11v-13l1 1a193 193 0 0056 19l20 2c19 0 37-3 53-10a144 144 0 0077-67 110 110 0 006-79c-4-9-9-18-16-27-6-8-15-16-25-24a209 209 0 00-54-27 585 585 0 00-52-16l-18-7-9-5a32 32 0 01-10-16l-1-10 3-12 8-10c4-3 8-6 13-7a49 49 0 0131 0l13 8 10 13 5 18h92V124h-81v11l-1-1a131 131 0 00-51-19l-19-1c-19 0-37 2-54 8-16 6-31 14-44 25s-23 24-30 38a105 105 0 00-4 79c4 10 10 20 18 28 8 9 18 17 30 25a235 235 0 0057 24 1890 1890 0 0045 15l16 7a41 41 0 0115 13 33 33 0 015 18 31 31 0 01-13 25c-4 4-9 6-14 8a57 57 0 01-36-1c-5-2-9-5-13-9s-7-10-9-16l-5-24h-101v124h86z"
      fill="#231f20"
    />
    <path
      d="M586 990h-91l-86-304h-31v-78h163v78h-34l40 156 58-234h87l58 240 40-162h-34v-78h162v78h-31l-86 304h-90l-63-238z"
      fill="#fff"
    />
    <path
      d="M586 991h-91l-1-1-85-303h-32v-80h164v80h-33l39 152 57-231v-1h89v1l57 237 39-158h-34v-80h164v80h-31l-86 303v1h-92v-1l-62-235-61 235v1zm-90-2h90l62-237v-2l1 2 62 237h90l86-303h31v-77H756v77h35-1l-40 162h-1l-57-239h-87l-57 233h-2l-40-156h34v-77H379v77h31l86 303z"
      fill="#231f20"
    />
    <path
      d="M1135 806l-28-87-28 87zm-78 74l-12 31h34v79H904v-79h39l91-225h-39v-78h151l123 303h39v79h-175v-79h33l-10-31z"
      fill="#fff"
    />
    <path
      d="M1134 805l-27-83-27 83zm-27-86l28 87 1 1h-58v-1l28-87 1-2zm-50 161l-11 30h34v81H903v-81h40l90-223h-39v-80h153l122 303h39v81h-176v-81h33l-10-30zm-12 31l11-32h100l11 32v1h-33v77h173v-77h-39v-1l-122-302H996v77h39l-91 225v1h-39v77h173v-77h-34z"
      fill="#231f20"
    />
    <path
      d="M1465 784h19c26 0 44-3 56-11 12-7 18-19 18-34 0-17-6-28-16-35s-28-10-53-10h-24zm0 127h24v79h-162v-79h39V686h-39v-78h162c34 0 59 1 74 5a118 118 0 0181 61c10 19 15 39 15 61a128 128 0 01-76 118l50 58h38v79h-87l-119-137z"
      fill="#fff"
    />
    <path
      d="M1466 784h18c13 0 24-1 33-3s17-5 23-8c5-4 10-9 13-14 2-6 4-13 4-20 0-8-1-15-4-21-2-6-6-10-11-13-5-4-12-6-21-8l-32-2h-23zm18 1h-20v-92h25c13 0 23 1 32 3s16 4 22 8c5 3 9 8 11 14 3 5 4 12 4 21 0 8-1 14-4 20s-8 11-14 15c-6 3-13 6-23 8-9 2-20 3-33 3zm-18 125h24v81h-164v-81h39V687h-39v-80h163a694 694 0 0174 5 127 127 0 0140 16 118 118 0 0141 46 129 129 0 0115 61 137 137 0 01-20 71 125 125 0 01-55 47l49 57h39v81h-88l-1-1-117-135zm22 2h-24v-61l1 2 119 136h86v-77h-37v-1l-51-58 1-1a134 134 0 0055-47 124 124 0 0020-70 131 131 0 00-15-61 121 121 0 00-40-45 103 103 0 00-40-16l-30-3-44-1h-161v77h38v226h-38v77h160v-77z"
      fill="#231f20"
    />
    <path
      d="M1911 912v-45h89v123h-294v-78h39V686h-39v-78h294v122h-89v-44h-67v68h89v85h-89v73z"
      fill="#fff"
    />
    <path
      d="M1911 911v-44h90v124h-296v-80h39V687h-39v-80h296v124h-90v-44h-67v66h90v87h-90v71zm1-43v44h-69v-74h89v-83h-89v-69h69v44h87V609h-293v77h39v226h-39v77h293V868z"
      fill="#231f20"
    />
    <path
      d="M2090 911V686h-39v-78h175v78h-37v70h119v-70h-38v-78h176v78h-40v225h40v79h-176v-79h38v-77h-119v77h37v79h-175v-79z"
      fill="#fff"
    />
    <path
      d="M2089 910V687h-39v-80h176v80h-36v68h117v-68h-37v-80h177v80h-40v223h40v81h-177v-81h37v-75h-117v75h37v81h-177v-81h1zm1-224v226h-38v77h173v-77h-37v-79h120v79h-37v77h174v-77h-39V685h39v-76h-174v76h37v71h-120v-71h37v-76h-173v76h38z"
      fill="#231f20"
    />
    <path
      d="M2585 796c0 29 10 54 31 75s46 31 74 31a103 103 0 00105-106c0-28-10-52-30-71-20-20-45-30-73-30-30 0-55 10-76 29a94 94 0 00-31 72zm-102-1a183 183 0 0161-139 204 204 0 01147-56 210 210 0 01144 56c20 19 35 41 46 65a200 200 0 01-42 216 205 205 0 01-149 60 198 198 0 01-150-61 196 196 0 01-57-141"
      fill="#fff"
    />
    <path
      d="M2585 796a104 104 0 0031 74c11 11 22 18 34 24 13 5 26 7 40 7 15 0 28-2 41-7 12-5 23-13 34-23a101 101 0 0030-75 97 97 0 00-30-71 103 103 0 00-73-29 105 105 0 00-99 61c-5 12-8 25-8 39zm7 41a105 105 0 010-80 106 106 0 01100-62c14 0 27 2 40 7a104 104 0 0156 55 112 112 0 010 81c-5 12-12 24-23 34a103 103 0 01-75 31 102 102 0 01-74-32c-11-10-19-22-24-34zm-110-42a189 189 0 0162-140 209 209 0 01187-52 204 204 0 01166 193 210 210 0 01-14 77 189 189 0 01-44 64 207 207 0 01-149 61 237 237 0 01-83-15 192 192 0 01-67-46 209 209 0 01-43-65 192 192 0 01-15-77zm6-39a188 188 0 00-1 79 190 190 0 0054 101 208 208 0 0067 46 201 201 0 0082 15 216 216 0 00116-35c12-7 22-16 32-25a199 199 0 0054-102 209 209 0 000-78 184 184 0 00-58-100 207 207 0 00-184-53 208 208 0 00-131 82 189 189 0 00-31 70"
      fill="#231f20"
    />
    <path
      d="M3169 686h-27v-78h159v78h-33v162c0 48-13 85-39 111-26 25-63 38-112 38-27 0-51-5-72-14-22-10-39-25-52-44-8-10-14-22-17-36s-5-32-5-55V686h-33v-78h158v78h-26v158c0 22 4 39 12 49 8 11 20 16 36 16 17 0 30-5 38-16 9-10 13-27 13-49z"
      fill="#fff"
    />
    <path
      d="M3168 687h-26v-80h159v80h-33v161c0 24-3 45-9 64a123 123 0 01-77 76 196 196 0 01-138-5 131 131 0 01-52-44 91 91 0 01-16-36l-4-24-1-31V687h-33v-80h159v80h-26v157c0 11 1 21 3 29s5 15 8 20c4 5 9 9 15 12 6 2 13 4 21 4s16-2 22-4c7-3 12-7 16-12 4-6 7-12 9-20s3-18 3-29V687zm-25-1h27v158c0 11-1 21-4 29-2 8-5 15-9 21-4 5-10 9-16 12-7 2-14 4-23 4-8 0-16-1-22-4s-11-7-15-12-7-12-8-21c-2-8-3-18-3-29V686h26v-77h-157v77h33v162a343 343 0 005 55 106 106 0 0016 36 128 128 0 0052 43 193 193 0 0073 15c23 0 45-4 63-10a122 122 0 0076-76c7-18 10-39 10-63V686h33v-77h-157v77z"
      fill="#231f20"
    />
    <path
      d="M3428 976v13h-79V864h92c2 18 6 31 13 40 6 8 15 12 27 12 11 0 21-3 28-10 8-7 12-15 12-25 0-7-2-12-5-18-3-5-7-9-13-12-7-5-19-10-35-15l-32-12c-29-12-51-26-66-44a96 96 0 01-21-63c0-33 11-61 35-83a125 125 0 01123-29c10 3 20 8 30 15v-10h74v109h-84c-3-13-7-23-14-30s-15-11-25-11-18 3-25 9-10 14-10 22c0 7 2 13 5 18 3 6 7 10 12 13l40 14c18 6 31 11 40 16 25 12 43 27 55 44s18 36 18 58c0 34-12 64-37 88a124 124 0 01-158 16"
      fill="#fff"
    />
    <path
      d="M3428 977v13h-79V863h93l4 24 8 16c3 4 7 7 12 9 4 2 9 3 15 3l15-2c5-2 9-4 13-8 4-3 7-7 8-11a33 33 0 002-22 33 33 0 00-16-21l-14-7a336 336 0 00-41-14l-12-5c-15-6-28-13-39-20-11-8-20-16-27-25a96 96 0 01-22-63 113 113 0 0136-84c12-11 26-20 40-25a138 138 0 0183-4 100 100 0 0129 15v-10h75v111h-85v-1l-5-17-8-13a33 33 0 00-25-10l-14 2a33 33 0 00-18 17c-2 3-2 7-2 11l1 10a32 32 0 009 15l7 5 16 7a602 602 0 0047 16l16 7c13 6 23 13 33 20 9 8 17 16 23 24a97 97 0 0118 59 121 121 0 01-38 89c-12 12-26 22-41 28a127 127 0 01-117-12zm-1 11v-13l1 1a167 167 0 0034 15 115 115 0 0034 6 126 126 0 00127-125 102 102 0 00-18-58c-6-8-14-16-23-24a184 184 0 00-49-27 485 485 0 00-47-16l-16-7a32 32 0 01-13-12l-3-9-2-10a29 29 0 0111-22c3-3 7-6 11-7a40 40 0 0128 0l12 8a73 73 0 0114 30l82 1V611h-73v11l-1-1a114 114 0 00-29-15 100 100 0 00-33-5 120 120 0 00-115 71 114 114 0 00-4 79c3 10 9 20 16 28 7 9 16 17 27 25a203 203 0 0051 24 1503 1503 0 0040 15l15 7a36 36 0 0113 13 35 35 0 012 32c-2 4-5 8-9 11-4 4-8 6-13 8a46 46 0 01-32-1c-5-2-9-5-12-9l-8-16-4-24h-91v124h77z"
      fill="#231f20"
    />
    <path
      d="M3872 912v-45h89v123h-295v-78h39V686h-39v-78h295v122h-89v-44h-68v68h90v85h-90v73z"
      fill="#fff"
    />
    <path
      d="M3871 911v-44h90v124h-295v-80h39V687h-39v-80h295v124h-90v-44h-66v66h89v87h-89v71zm2-43v44h-69v-74h89v-83h-89v-69h69v44h87V609h-293v77h39v226h-39v77h293V868z"
      fill="#231f20"
    />
    <path d="M3990 572H366v-36h3624z" fill="#fff" />
    <path d="M3990 573H365v-37h3626v37zm-3623-1h3623v-35H367z" fill="#231f20" />
    <path
      d="M184 409c-7 3-14 7-17 0-2-3-3-10-4-15-3-18-6-22 12-40a451 451 0 01166-97c22-6 23 2 40 14 5 5-1 19-3 26l-38 124h1c3-2 7-4 9 0 2 5-3 18-6 24-3 8-10 29-20 34-8 4-19 5-22-1-11-24 23-110 35-141l19-47c-56 29-109 62-157 100v1c5-2 28-11 30-6s-13 8-26 14zM426 379c12-17 53-85 71-94 8-4 19-2 21 4 2 5-6 22-8 27-12 28-11 28-10 31 2 3 6 3 10 1 11-5 28-30 36-39l6 9c-11 16-40 59-58 68-10 5-18 1-21-6-7-15 7-44 14-61l-1-1c-43 56-47 90-58 95-11 6-22-12-24-18-8-17-5-32 1-52 17-66 62-140 73-146 6-2 15-5 17 0 8 16-60 147-73 173z"
      fill="#ed1c24"
    />
    <path
      d="M610 234c-1-2-3-2-5-1a78 78 0 00-30 53c25-22 37-48 35-52zm58 19c-12 27-37 67-70 83-24 12-42 2-50-14-16-31 17-87 60-107 10-5 24-10 28 0 6 12-2 37-58 83l2 6c3 10 15 8 26 2 23-11 43-42 55-61z"
      fill="#ed1c24"
    />
  </svg>
)

export default Logo
