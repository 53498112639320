import React from "react"
import styled from "styled-components"
import Inner from "../../../layout/pageInner/Inner"
import AreaLink from "./AreaLink"
import FeaturedAreaLink from "./FeaturedAreaLink"
import { graphql, useStaticQuery } from "gatsby"

const ContactMenu = () => {
  const {
    allLocalWpGraphQlBranches: { nodes },
  } = useStaticQuery(BRANCH_QUERY)
  return (
    <Wrap>
      <Inner>
        <Grid>
          <Categories>
            {nodes.map((node, i) => (
              <AreaLink key={i} node={node} />
            ))}
          </Categories>
        </Grid>
      </Inner>
    </Wrap>
  )
}

export default ContactMenu

const Wrap = styled.div``

const Categories = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 40px 0;
  grid-gap: 20px;
  h3 {
    color: #fff;
    margin-bottom: 6px;
  }
`

const BRANCH_QUERY = graphql`
  {
    allLocalWpGraphQlBranches {
      nodes {
        title
        slug
        branch_details {
          telephoneNumbers {
            phone1
            phone2
            phone3
          }
        }
      }
    }
  }
`
