import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import colors from "../colors"
import Helmet from "react-helmet"

const theme = {
  boxShadow: "0 2px 5px 0 rgba(72,84,100,0.1)",
  maxWidth: "1240px",
  vPad: "80px",
  breakLarge: "1025px",
  breakMedium: "960px",
  breakSmall: "768px",
  breakTiny: "480px",
  breakXTiny: "320px",
  headingFont: '"Roboto", "Arial Black", sans-serif',
  headingColor: colors.midnight["060"],
  bodyFont: '"Roboto", Arial, Helvetica, sans-serif',
  bodyColor: colors.midnight["060"],
  colors,
}

const fontConfig = (size, weight = "400", height = "1.5", style = "normal") =>
  `font-size: ${size}rem;
  font-weight: ${weight};
  line-height: ${height};
  font-style: ${style};`

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 16px;
  }
    *, *:before, *:after {
      box-sizing: inherit;
      font-family: 'Roboto', Helvetica, sans-serif;
    }
    body.menu-fixed,
    body.drawer-fixed {
      overflow: hidden;
      width:100%;
      position: fixed;
    }
    body {
      line-height: 1.5;
      padding: 0;
      margin: 0;
      color: ${theme.bodyColor};
      font-family: ${theme.bodyFont};
      ${fontConfig(1)};
      -webkit-overflow-scrolling: touch;  
    }
    h1, h2, h3 , h4, h5, h6 {
      margin: 0;
      padding: 0;
      font-family: ${theme.headingFont};
      color: ${theme.headingColor};
      &.uppercase {
        text-transform: uppercase;
      }
      &.centered {
        text-align: center;
      }
    }
    h1.hero {
      /* 48px */
      ${fontConfig(3, 300)}
    }
    h1 {
      /* 39px */
      ${fontConfig(2.4375, 300)}
    }
    h2 {
      /* 31px */
      ${fontConfig(1.9375, 300)}
    }
    h3 {
      /* 25px */
      ${fontConfig(1.5625, 300)}
    }
    h4 {
      /* 20px */
      ${fontConfig(1.25, 300)}
    }
    h5 {
      /* 16px */
      ${fontConfig(1, 300)}
    }
    h6 {
      /* 16px */
      ${fontConfig(1, 500)}
      text-transform: uppercase;
    }
    p {
      color: ${({ theme }) => theme.bodyTextGrey};
      /* 16 */
      ${fontConfig(1)}
      &.mega{
        /* 20 */
        ${fontConfig(1.25, 300)}
      }
      &.large{
        /* 18 */
        ${fontConfig(1.125, 300)}
      }
      &.small{
        /* 14 */
        ${fontConfig(0.875)}
      }
      &.tiny{
        /* 12 */
        ${fontConfig(0.75)}
      }
    }
    a {
      text-decoration: none;
      color: #0988bb;
    }
    img {
      max-width: 100%;
      height:auto;
    }

  .ReactModal__Overlay {
		opacity: 0;
		transition: opacity 400ms ease-in-out;
    z-index: 1;
	}

	.ReactModal__Overlay--after-open {
		opacity: 1;
	}

	.ReactModal__Overlay--before-close {
		opacity: 0;
	}

  .ReactModal__Content{
    width: 600px;
    @media (max-width: 768px) {
      width: 350px;
    }
  }

  input,button{
  // input font size must be at least 16px otherwise it causes zoom-in on mobile
    font-size: 16px;
  }

  //privy styling fixes
  @media (max-width: ${theme.breakSmall}) {
    .clearfix.privy-mobile-bar-top-content {
      display: none;
    }
    .privy-bar-container.privy-top {
      position: absolute;
      top: 70px!important;
    }
  }
  `

const ThemeConfig = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet key={"createGlobalStyle"}>
        <noscript>{`
          <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap" />
        `}</noscript>
      </Helmet>
      <GlobalStyle />
      <>{children}</>
    </ThemeProvider>
  )
}

export default ThemeConfig
