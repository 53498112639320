import React from "react"
import { Link } from "gatsby"

const httpStart = "http:/"
const httpsStart = "https:/"
const sizeUndefined = "?size=undefined"
export const LinkWrapper = ({ to, as, ...args }) => {
  try {
    if (to) {
      if (to == "undefined") {
        console.log("DEBUG LinkWrapper undefined to = ", to)
        console.log("DEBUG LinkWrapper undefined args = ", args)
        to = "/"
      } else if (to.endsWith(sizeUndefined)) {
        to = to.slice(0, to.length - sizeUndefined.length)
      } else if (to.startsWith(httpStart)) {
        console.log("DEBUG LinkWrapper to starts with http:/ to = ", to)
        console.log("DEBUG LinkWrapper to starts with http:/ args = ", args)
        to = to.slice(httpStart.length)
      } else if (to.startsWith(httpStart)) {
        console.log("DEBUG LinkWrapper to starts with https:/ to = ", to)
        console.log("DEBUG LinkWrapper to starts with https:/ args = ", args)
        to = to.slice(httpStart.length)
      } else if (to.startsWith(httpsStart)) {
        to = to.slice(httpsStart.length)
      } else if (to.charAt(0) != "/") {
        to = `/${to}`
      }
    } else {
      to = "/"
    }
    if (to.toLowerCase().includes("undefined")) {
      console.log("DEBUG LinkWrapper caught undefined in url. to = ", to)
      console.log("DEBUG LinkWrapper caught undefined in url. args = ", args)
    }
    if (!to.endsWith("/") && !to.includes("?")) {
      to = `${to}/`
    }
  } catch (err) {
    const error_msg = `
      LinkWrapper caught error: ${JSON.stringify(err, null, 2)}
      LinkWrapper caught error on to: ${JSON.stringify(to, null, 2)}
      LinkWrapper caught error on args: ${JSON.stringify(args, null, 2)}
    `
    console.log(error_msg)
    throw new Error(error_msg)
  }
  if (as != undefined) {
    if (as == LinkWrapper) {
      as = Link
    }
    return <Link to={to} as={as} {...args} />
  }
  return <Link to={to} {...args} />
}

export default LinkWrapper
